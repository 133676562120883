import React from 'react';

import NavBarNavButton from 'components/NavBarNavButton';

import NavActions from 'lib/NavActions';
import { NavigationConstants, ContainerConstants, FooterConstants } from 'lib/Constants';
import { ReactComponent as ArrowUpSVG } from '../../assets/icons/arrow-up.svg';
import FooterStyles from './_FooterStyles.module.scss';

const Footer = (): JSX.Element => {
    const {
        navToLogin,
        navToProducts,
        navToTermsAndConditions,
        navToCooperCompanies,
    } = NavActions;

    const {
        productsPage,
        termsAndConditionsPage,
        loginPage,
        cooperCompaniesPage,
    } = NavigationConstants;

    const {
        homePage: {
            howToJoinContainerId,
            faqContainerId,
        },
    } = ContainerConstants;

    const {
        cooperVisionSGSiteUrl,
        cooperVisionAsiaPacificOfficeAddress,
    } = FooterConstants;

    const officeAddress = cooperVisionAsiaPacificOfficeAddress.split(',').map(item => (
        <span key={item}>
            {item}
            <br />
        </span>
    ));

    return (
        <footer className={FooterStyles.container}>
            <div className={FooterStyles['content-container']}>
                <div className={FooterStyles['content-subcontainer']}>
                    <NavBarNavButton
                        label='How to Join'
                        href={`/#${howToJoinContainerId}`}
                    />

                    <NavBarNavButton
                        label='FAQ'
                        href={`/#${faqContainerId}`}
                    />

                    <NavBarNavButton
                        label='Products'
                        onClick={navToProducts}
                        href={productsPage}
                    />

                    <NavBarNavButton
                        label='Terms & Conditions'
                        onClick={navToTermsAndConditions}
                        href={termsAndConditionsPage}
                    />

                    <NavBarNavButton
                        label='Login / Sign Up'
                        onClick={navToLogin}
                        href={loginPage}
                    />
                </div>

                <div className={FooterStyles['content-subcontainer']}>
                    <div>
                        Contact Us
                    </div>

                    <div>
                        CooperVision Singapore

                        <br />

                        <span>
                            {cooperVisionSGSiteUrl}
                        </span>
                    </div>

                    <div>
                        CooperVision Asia Pacific

                        <br />

                        {officeAddress}
                    </div>
                </div>

                <img
                    className={FooterStyles['footer-logo-image']}
                    alt='coopervision logo'
                />

                <button
                    type='button'
                    aria-label='scroll to top'
                    onClick={() => window.scrollTo(0, 0)}
                    className={FooterStyles['arrow-up-button']}
                >
                    <ArrowUpSVG />
                </button>
            </div>

            <div className={FooterStyles['copyright-container']}>
                © 2023 CooperVision | Part of

                {' '}

                <a
                    onClick={(e) => {
                        e.preventDefault();
                        navToCooperCompanies();
                    }}
                    href={cooperCompaniesPage}
                >
                    CooperCompanies
                </a>
            </div>
        </footer>
    );
};

export default Footer;
