import { push, replace } from 'redux-first-history';

import { store } from 'redux/store';
import { NavigationConstants, ContainerConstants } from './Constants';

const {
    productsPage,
    profilePage,
    loginPage,
    homePage,
    termsAndConditionsPage,
    forgotPasswordPage,
    verifyOtpPage,
    resetPasswordPage,
    privacyPolicyPage,
    cooperCompaniesPage,
} = NavigationConstants;

const {
    homePage: {
        howToJoinContainerId,
        faqContainerId,
    },
} = ContainerConstants;

const navPush = (routeName: string) => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
    });
    store.dispatch(push(routeName));
};

const navReplace = (routeName: string) => {
    store.dispatch(replace(routeName));
};

const navResetToLogin = (): void => navReplace(loginPage);

const navToLogin = (): void => navPush(loginPage);

const navToHome = (): void => navPush(homePage);

const navToHomeHowToJoinSection = (): void => navPush((`${homePage}#${howToJoinContainerId}`));

const navToHomeFAQSection = (): void => navPush((`${homePage}#${faqContainerId}`));

const navToProducts = (): Window | null => window.open(productsPage, '_blank', 'noopener noreferrer');

const navToProfile = (): void => navPush(profilePage);

const navToTermsAndConditions = (): void => navPush(termsAndConditionsPage);

const navToForgotPassword = (): void => navPush(forgotPasswordPage);

const navToVerifyOtp = (email: string, otp?: string): void => {
    if (otp) {
        navPush(`${verifyOtpPage}?email=${email}&otp=${otp}`);
    } else {
        navPush(`${verifyOtpPage}?email=${email}`);
    }
};

const navtoResetPassword = (email: string, otp: string): void => navPush(`${resetPasswordPage}?email=${email}&otp=${otp}`);

const navToPrivacyPolicy = (): Window | null => window.open(privacyPolicyPage, '_blank', 'noopener noreferrer');

const navToCooperCompanies = (): Window | null => window.open(cooperCompaniesPage, '_blank', 'noopener noreferrer');

export default {
    navToLogin,
    navResetToLogin,
    navToHome,
    navToHomeHowToJoinSection,
    navToHomeFAQSection,
    navToProducts,
    navToProfile,
    navToTermsAndConditions,
    navToForgotPassword,
    navToVerifyOtp,
    navtoResetPassword,
    navToPrivacyPolicy,
    navToCooperCompanies,
};
