import React from 'react';
import { connect } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { AuthPageTabReduxTypes } from 'redux/slices/auth/types';
import CombinedActions from 'redux/CombinedActions';

import ImageAndDescriptionCard from 'components/ImageAndDescriptionCard';
import CTAButton from 'components/CTAButton';
import { ContainerConstants, NavigationConstants } from 'lib/Constants';
import Utils from 'lib/Utils';
import { ProfilePageTabReduxTypes } from 'redux/slices/profile/types';
import NavActions from 'lib/NavActions';

import HowtoJoinStep1 from '../../../../assets/images/icon-Step-1.svg';
import HowtoJoinStep2 from '../../../../assets/images/icon-Step-2.svg';
import HowtoJoinStep3 from '../../../../assets/images/icon-Step-3.svg';

import HowToJoinSectionStyles from './_HowToJoinSectionStyles.module.scss';

const {
    homePage: {
        howToJoinContainerId,
    },
} = ContainerConstants;

interface HowToJoinSectionProps {
    setAuthPageCurrentTab: (tab: AuthPageTabReduxTypes) => void;
    setProfilePageCurrentTab: (tab: ProfilePageTabReduxTypes) => void;
}

const HowToJoinSection = (props: HowToJoinSectionProps): JSX.Element => {
    const {
        setAuthPageCurrentTab,
        setProfilePageCurrentTab,
    } = props;

    const isAuthenticated = Utils.Auth.getAuthToken();
    const { navToProfile, navToLogin } = NavActions;

    const { homePage: {
        howToJoinBuyDescription,
        howToJoinLoginDescription,
        howToJoinRedeemDescription,
    } } = ContainerConstants;

    const {
        loginPage,
    } = NavigationConstants;

    const renderLoginDescription = () => {
        const loginButtonText = 'logging in';
        const signUpButtonText = 'sign up';

        return (
            <p>
                {howToJoinLoginDescription.substring(0, howToJoinLoginDescription.indexOf(loginButtonText))}

                <a
                    onClick={(e) => {
                        e.preventDefault();

                        if (!isAuthenticated) {
                            setAuthPageCurrentTab('Login');
                            navToLogin();
                        } else {
                            setProfilePageCurrentTab('Upload Receipt');
                            navToProfile();
                        }
                    }}
                    href={loginPage}
                >
                    {loginButtonText}
                </a>

                {howToJoinLoginDescription.substring(howToJoinLoginDescription.indexOf(loginButtonText) + loginButtonText.length, howToJoinLoginDescription.indexOf(signUpButtonText))}

                <a
                    onClick={(e) => {
                        e.preventDefault();

                        if (!isAuthenticated) {
                            setAuthPageCurrentTab('Sign Up');
                            navToLogin();
                        } else {
                            setProfilePageCurrentTab('Upload Receipt');
                            navToProfile();
                        }
                    }}
                    href={loginPage}
                >
                    {signUpButtonText}
                </a>

                {howToJoinLoginDescription.substring(howToJoinLoginDescription.indexOf(signUpButtonText) + signUpButtonText.length)}
            </p>
        );
    };

    return (
        <div
            id={howToJoinContainerId}
            className={HowToJoinSectionStyles.container}
        >
            <h2>
                How To Join
            </h2>

            <div className={HowToJoinSectionStyles['card-container']}>
                <ImageAndDescriptionCard
                    imageSrc={HowtoJoinStep1}
                    imageAlt='step-1'
                    description={howToJoinBuyDescription}
                />

                <ImageAndDescriptionCard
                    imageSrc={HowtoJoinStep2}
                    imageAlt='step-2'
                    description={renderLoginDescription()}
                />

                <ImageAndDescriptionCard
                    imageSrc={HowtoJoinStep3}
                    imageAlt='step-3'
                    description={howToJoinRedeemDescription}
                />
            </div>

            {isAuthenticated
                ? (
                    <CTAButton
                        label='Redeem Now'
                        onClick={navToProfile}
                    />
                )
                : (
                    <CTAButton
                        label='Sign Up Now'
                        onClick={() => {
                            setAuthPageCurrentTab('Sign Up');
                            navToLogin();
                        }}
                    />
                )}
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setAuthPageCurrentTab: (tab: AuthPageTabReduxTypes) => dispatch(CombinedActions.authSetAuthPageCurrentTab(tab)),
    setProfilePageCurrentTab: (tab: ProfilePageTabReduxTypes) => dispatch(CombinedActions.profileSetProfilePageCurrentTab(tab)),
});

export default connect(null, mapDispatchToProps)(HowToJoinSection);
