import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import NavBar from 'layout/NavBar';
import AppRoot from 'containers/AppRoot';
import Footer from 'layout/Footer';

import './sass/global.css';
import { connect } from 'react-redux';
import { AppDispatch } from 'redux/store';
import CombinedActions from 'redux/CombinedActions';
import packageJson from '../package.json';

interface AppProps {
    startup: () => void;
}

const App = (props: AppProps): JSX.Element => {
    const {
        startup,
    } = props;

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`App version: ${packageJson.version} (${process.env.REACT_APP_ENV})`);

        startup();

        // initialize gtm
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GTM_ID || '',
        };

        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        <div className='app-container'>
            <NavBar />

            <AppRoot />

            <Footer />
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    startup: () => dispatch(CombinedActions.authStartupAttempt()),
});

export default connect(null, mapDispatchToProps)(App);
