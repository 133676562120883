import React, { useEffect, useState } from 'react';

import { Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import CTAButton from 'components/CTAButton';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import CombinedActions from 'redux/CombinedActions';
import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import { VerifyOtpReduxParams } from 'redux/slices/auth/types';
import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';

import VerifyOtpStyles from './_VerifyOtpStyles.module.scss';

interface VerifyOtpProps {
    submitVerifyOtpIsLoading: boolean;
    submitVerifyOtpError: string;
    submitVerifyOtp: (param: VerifyOtpReduxParams) => void;
}

const VerifyOtp = (props: VerifyOtpProps): JSX.Element => {
    const {
        submitVerifyOtpIsLoading,
        submitVerifyOtpError,
        submitVerifyOtp,
    } = props;

    const {
        GTM: {
            triggerPageView,
        },
    } = Utils;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramEmail = queryParams.get('email');
    const paramOtp = queryParams.get('otp');

    const [email, setEmail] = useState('');

    const [otp, setOtp] = useState('');

    triggerPageView();

    useEffect(() => {
        if (!paramEmail && !paramOtp) {
            setEmail('');
            setOtp('');
            NavActions.navToForgotPassword();
        }
        if (paramEmail) {
            setEmail(paramEmail);
        }
        if (paramOtp) {
            setOtp(paramOtp);
        }
    }, [paramEmail, paramOtp]);

    const handleVerifyClick = () => {
        submitVerifyOtp({ email, otp });
    };

    return (
        <div className={VerifyOtpStyles.container}>
            <h1>
                Verify OTP
            </h1>
            <div className={VerifyOtpStyles.subcontainer}>
                <Form>
                    <FormGroup className={VerifyOtpStyles['form-group']}>
                        <Input
                            type='text'
                            name='otp'
                            placeholder='Enter OTP'
                            value={otp}
                            onChange={(e) => setOtp(e.currentTarget.value)}
                            invalid={submitVerifyOtpError ? true : undefined}
                        />

                        <FormFeedback
                            invalid={submitVerifyOtpError ? 'true' : undefined}
                            className={VerifyOtpStyles['form-feedback']}
                        >
                            Invalid OTP!
                        </FormFeedback>
                    </FormGroup>
                </Form>
                <div className={VerifyOtpStyles['button-container']}>
                    <CTAButton
                        label='Verify'
                        onClick={handleVerifyClick}
                        isLoading={submitVerifyOtpIsLoading}
                    />
                </div>

                {submitVerifyOtpError
                    && (
                        <p className={VerifyOtpStyles.error}>
                            {submitVerifyOtpError}
                        </p>
                    )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    submitVerifyOtpIsLoading: CombinedSelectors.auth.getVerifyOtpAttempting(state.auth),
    submitVerifyOtpError: CombinedSelectors.auth.getVerifyOtpError(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitVerifyOtp: (param: VerifyOtpReduxParams) => dispatch(CombinedActions.authVerifyOtpAttempt(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);
