import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import CTAButton from 'components/CTAButton';
import CombinedActions from 'redux/CombinedActions';
import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';

import Utils from 'lib/Utils';

import ForgotPasswordStyles from './_ForgotPassswordStyles.module.scss';

interface ForgotPasswordProps {
    submitForgotPasswordIsLoading: boolean;
    submitForgotPasswordError: string;
    submitForgotPassword: (email: string) => void;
}

const ForgotPassword = (props: ForgotPasswordProps): JSX.Element => {
    const {
        submitForgotPasswordIsLoading,
        submitForgotPasswordError,
        submitForgotPassword,
    } = props;

    const {
        GTM: {
            triggerPageView,
        },
    } = Utils;

    const [email, setEmail] = useState('');

    triggerPageView();

    const handleSubmitClick = () => {
        submitForgotPassword(email);
    };

    return (
        <div className={ForgotPasswordStyles.container}>
            <h1>
                Forgot Password
            </h1>
            <div className={ForgotPasswordStyles.subcontainer}>
                <Form>
                    <FormGroup className={ForgotPasswordStyles['form-group']}>
                        <Label
                            className={ForgotPasswordStyles.label}
                            for='email'
                        >
                            Email
                        </Label>

                        <Input
                            type='text'
                            name='email'
                            placeholder='enter email address'
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            invalid={submitForgotPasswordError ? true : undefined}
                        />

                        <FormFeedback
                            invalid={submitForgotPasswordError ? 'true' : undefined}
                            className={ForgotPasswordStyles['form-feedback']}
                        >
                            Invalid email address!
                        </FormFeedback>
                    </FormGroup>
                </Form>
                <div className={ForgotPasswordStyles['button-container']}>
                    <CTAButton
                        label='Submit'
                        onClick={handleSubmitClick}
                        isLoading={submitForgotPasswordIsLoading}
                    />
                </div>

                {submitForgotPasswordError
                    && (
                        <p className={ForgotPasswordStyles.error}>
                            {submitForgotPasswordError}
                        </p>
                    )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    submitForgotPasswordIsLoading: CombinedSelectors.auth.getForgotPasswordAttempting(state.auth),
    submitForgotPasswordError: CombinedSelectors.auth.getForgotPasswordError(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitForgotPassword: (email: string) => dispatch(CombinedActions.authForgotPasswordAttempt({ email })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
