import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';
import AuthScreen from 'containers/auth';
import HomeScreen from 'containers/home';
import TermsAndConditionsScreen from 'containers/termsAndConditions';
import ForgotPasswordScreen from 'containers/forgotPassword';
import VerifyOtpScreen from 'containers/verifyOtp';
import ResetPasswordScreen from 'containers/resetPassword';
import Profile from 'containers/profile';

import PrivateRoute from './PrivateRoutes';

const PrivateBucket: FunctionComponent = () => {
    return (
        <Route
            path='/'
            element={<PrivateRoute />}
        >
            <Route
                path='/profile'
                element={<Profile />}
            />
        </Route>
    );
};

const NavRoutes: FunctionComponent = (props) => {
    return (
        <Router
            history={history}
        >
            <Routes>
                <Route
                    path='/'
                    element={<HomeScreen />}
                />

                <Route
                    path='/login'
                    element={<AuthScreen />}
                />

                <Route
                    path='/forgot-password'
                    element={<ForgotPasswordScreen />}
                />

                <Route
                    path='/verify-otp'
                    element={<VerifyOtpScreen />}
                />

                <Route
                    path='/reset-password'
                    element={<ResetPasswordScreen />}
                />

                <Route
                    path='/terms-and-conditions'
                    element={<TermsAndConditionsScreen />}
                />

                <Route
                    path='*'
                    element={<Navigate replace to='/' />}
                />

                {PrivateBucket(props)}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
