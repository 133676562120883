import React from 'react';

import HomePageBodyStyles from './_HomePageBodyStyles.module.scss';
import HowToJoinSection from './HowToJoinSection';
import FAQSection from './FAQSection';
import CooperVisionProducts from './CooperVisionProducts';

const HomePageBody = (): JSX.Element => {
    return (
        <div className={HomePageBodyStyles.container}>
            <HowToJoinSection />

            <CooperVisionProducts />

            <FAQSection />
        </div>
    );
};

export default HomePageBody;
