const OutletList = [
    {
        ECP: '@EYEF@CTOR',
        Address: 'BLK 531 UPPER CROSS STREET #02-67 SINGAPORE 050531',
    },
    {
        ECP: "20/20 EYECARE PTE LTD PEOPLE'S PARK CENTER",
        Address: "101 UPPER CROSS STREET #02-05 PEOPLE'S PARK CENTRE SINGAPORE 058357",
    },
    {
        ECP: '20/20 VISION OPTOMETRISTS WOODLANDS PTE LTD',
        Address: '50A CHOA CHU KANG LOOP #01-01/02 CHOA CHU KANG MRT STATION SINGAPORE 689959',
    },
    {
        ECP: '235 YISHUN OPTICS',
        Address: '235 YISHUN STREET 21 #01-452 SINGAPORE 760235',
    },
    {
        ECP: '66 EYEWEAR BUKIT PANGJANG',
        Address: 'BLK 258 BUKIT PANJANG RING ROAD #01-62 SINGAPORE 670258',
    },
    {
        ECP: '66 OPTICS & CONTACT LENS CTR PTE LTD',
        Address: 'BLK 103 YISHUN RING ROAD #01-75 SINGAPORE 760103',
    },
    {
        ECP: '888 VISUAL OPTICS AND MERCHANDISE',
        Address: 'BLK 888 WOODLANDS DRIVE 50 #01-731B SINGAPORE 730888',
    },
    {
        ECP: 'ACE OPTICAL GROUP PTE LTD',
        Address: '449 ANG MO KIO AVENUE 10 #01-1731 SINGAPORE 560449',
    },
    {
        ECP: 'ADMIRALTY OPTICAL',
        Address: 'BLK 678 WOODLAND ADMIRALTY AVENUE 6 #01-728 SINGAPORE 730678',
    },
    {
        ECP: 'ADULT & CHILD EYE (ACE) CLINIC PTE LTD',
        Address: '38 IRRAWADDY ROAD #07-43 MT ELIZABETH NOVENA SPECIALIST CENTRE SINGAPORE 329563',
    },
    {
        ECP: 'AEROV PTE LTD',
        Address: '6 SIN MING ROAD #01-18 SIN MING PLAZA SINGAPORE 575585',
    },
    {
        ECP: 'AI MUI OPTICAL & LADY WEAR',
        Address: 'BLK 178 TOA PAYOH CENTRAL #01-216 SINGAPORE 310178',
    },
    {
        ECP: 'ALAN OPTICS',
        Address: '587 BUKIT TIMAH ROAD #02-24 CORONATION SHOPPING PLAZA SINGAPORE 269707',
    },
    {
        ECP: 'ALEXIS EYEWEAR (OPTICAL) BOUTIQUE',
        Address: '290 ORCHARD ROAD #03-31 PARAGON SINGAPORE 238859',
    },
    {
        ECP: 'ALL BEST OPTIQUE PTE LTD',
        Address: 'BLK 106 YISHUN RING ROAD #01-165 SINGAPORE 760106',
    },
    {
        ECP: 'ANCO OPTICS & CONTACT LENSES',
        Address: 'BLK 352 CLEMENTI AVENUE 2 #01-135 SINGAPORE 120352',
    },
    {
        ECP: 'ANCO OPTICS & CONTACT LENSES',
        Address: 'BLK 201D TAMPINES STREET 21 #01-1107 SINGAPORE 524201',
    },
    {
        ECP: 'ART EYEWEAR',
        Address: '119 ALJUNIED AVENUE 2 #01-38 SINGAPORE 380119',
    },
    {
        ECP: 'ARTS VISION OPTICS & CONTACT LENS CENTRE',
        Address: 'BLK 208 HOUGANG STREET 21 #01-215 SINGAPORE 530208',
    },
    {
        ECP: 'AS OPTICS',
        Address: '304 ORCHARD ROAD #B1-69 LUCKY PLAZA SINGAPORE 238863',
    },
    {
        ECP: 'ASIA EYE CENTRE CATARACT & RETINA SPECIALIST',
        Address: '101 IRRAWADDY ROAD #20-07 ROYAL SQUARE MEDICAL CENTRE SINGAPORE 329565',
    },
    {
        ECP: 'ASIA OPTICS AND CONTACT LENS CENTRE',
        Address: 'BLK 190 TOA PAYOH CENTRAL #01-582 SINGAPORE 310190',
    },
    {
        ECP: 'ASIA RETINA EYE SURGERY CENTRE',
        Address: '290 ORCHARD ROAD #15-10 PARAGON SINGAPORE 238859',
    },
    {
        ECP: 'ASSOCIATED OPTICAL CO',
        Address: "1 PARK ROAD #03-61 PEOPLE'S PARK COMPLEX SINGAPORE 059108",
    },
    {
        ECP: 'ATLANTIC OPTICS PTE LTD',
        Address: '501 ORCHARD ROAD #02-09A WHEELOCK PLACE SINGAPORE 238880',
    },
    {
        ECP: 'ATLAS EYE SPECIALIST CENTRE PTE LTD',
        Address: '290 ORCHARD ROAD #07-09 PARAGON SINGAPORE 238859',
    },
    {
        ECP: 'AZPEC EYECARE',
        Address: 'BLK 497 JURONG WEST STREET 41 #01-20 SINGAPORE 640497',
    },
    {
        ECP: 'AZPEC OPTICS',
        Address: '762 JURONG WEST STREET 75 #01-274 SINGAPORE 640762',
    },
    {
        ECP: 'B & W OPTICS & WATCH TRADING',
        Address: 'BLK 248 SIMEI STREET 3 #01-130 SINGAPORE 520248',
    },
    {
        ECP: 'B.S. MOEY-CHONG OPTOMETRIST & CONTACT LENS PRACTITIONER PTE LTD',
        Address: '68 ORCHARD ROAD #03-73A PLAZA SINGAPURA SINGAPORE 238839',
    },
    {
        ECP: 'BERLIN OPTICS AND CONTACT LENS CENTRE',
        Address: '111 NORTH BRIDGE ROAD #03-66 PENINSULA PLAZA SINGAPORE 179098',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '1 WOODLANDS SQUARE #02-17 CAUSEWAY POINT SINGAPORE 738099',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '315 OUTRAM ROAD #11-01 TAN BOON LIAT BUILDING SINGAPORE 169074',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '930 YISHUN AVENUE 2 #B1-34 NORTHPOINT SHOPPING CENTRE SINGAPORE 769098',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '238 THOMSON ROAD #02-39/40 NOVENA SQUARE SINGAPORE 307638',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '4 TAMPINES CENTRAL 5 #02-30 TAMPINES MALL SINGAPORE 529510',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '1 HARBOURFRONT WALK #B2-04 VIVOCITY SINGAPORE 098585',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '3 GATEWAY DRIVE #B1-32 WESTGATE SINGAPORE 608532',
    },
    {
        ECP: 'BETTER VISION PTE LTD',
        Address: '83 PUNGGOL CENTRAL #B1-02 WATERWAY POINT SINGAPORE 828761',
    },
    {
        ECP: 'BEYOND OPTICAL',
        Address: 'BLK 407 ANG MO KIO AVENUE 10 #01-747 SINGAPORE 560407',
    },
    {
        ECP: 'BLUE EYES OPTIC',
        Address: 'BLK 253 SERANGOON CENTRAL DRIVE #01-231 SINGAPORE 550253',
    },
    {
        ECP: 'BRIGHT OPTIQUE',
        Address: '21 CHOA CHU KANG NORTH 6 #B1-31 YEW TEE POINT SINGAPORE 689578',
    },
    {
        ECP: 'BRIGHT OPTIQUE',
        Address: 'BLK 848 YISHUN STREET 81 #01-128 SINGAPORE 760848',
    },
    {
        ECP: 'BRILLIANT OPTICS',
        Address: 'BLK 306 UBI AVENUE 1 #01-209 SINGAPORE 400306',
    },
    {
        ECP: 'C C CHUI OPTICAL COMPANY',
        Address: '400 ORCHARD ROAD #04-25 ORCHARD TOWERS SINGAPORE 238875',
    },
    {
        ECP: 'C.K. OPTICIANS PTE LTD',
        Address: 'BLK 177 TOA PAYOH CENTRAL #01-108 SINGAPORE 310177',
    },
    {
        ECP: 'C2 OPTICS CENTRE',
        Address: 'BLK 768 WOODLANDS AVENUE 6 #01-19 WOODLANDS MART SINGAPORE 730768',
    },
    {
        ECP: 'CANON OPTICS',
        Address: '150 ORCHARD ROAD #01-71 SINGAPORE 238841',
    },
    {
        ECP: 'CANTAT OPTICAL COMPANY',
        Address: 'BLK 59 NEW UPPER CHANGI ROAD #01-1286 SINGAPORE 461059',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '211 HENDERSON ROAD #03-02 HENDERSON INDUSTRIAL PARK SINGAPORE 159552',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '5 CHANGI BUSINESS PARK CENTRAL 1 #01-14 CHANGI CITY POINT CENTRAL 1 SINGAPORE 486038',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '180 KITCHENER ROAD #01-13 CITY SQUARE MALL SINGAPORE 208539',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '1 SENGKANG SQUARE #02-25 COMPASS ONE SINGAPORE 545078',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '583 ORCHARD ROAD #B1-17 FORUM GALLERIA SINGAPORE 238884',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '1 KIM SENG PROMENADE #02-110 GREAT WORLD CITY SINGAPORE 237997',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '7 HOLLAND VILLAGE WAY #02-12/13 SINGAPORE 275748',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '9 BISHAN PLACE #01-16 JUNCTION 8 SINGAPORE 579837',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '50 JURONG GATEWAY ROAD #02-48 JEM SINGAPORE 608549',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: "21 CHOA CHU KANG AVENUE 4 #B1-01 LOT ONE SHOPPERS' MALL SINGAPORE 689812",
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '304 ORCHARD ROAD #02-85 LUCKY PLAZA SINGAPORE 238863',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '930 YISHUN AVENUE 2 #B1-56 NORTHPOINT SHOPPING CENTRE SINGAPORE 769098',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '252 NORTH BRIDGE ROAD #03-08 RAFFLES CITY SHOPPING CENTRE SINGAPORE 179103',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '70 COMPASSVALE BOW #01-24 SENGKANG GRAND MALL SINGAPORE 544692',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '3 TEMASEK BOULEVARD #02-418 SUNTEC TOWER 3 SINGAPORE 038983',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '163 TANGLIN ROAD #02-19 TANGLIN MALL SINGAPORE 247933',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '4 TAMPINES CENTRAL 5 #02-03 TAMPINES MALL SINGAPORE 529510',
    },
    {
        ECP: 'CAPITOL OPTICAL COMPANY (PRIVATE) LIMITED',
        Address: '1 PASIR RIS CENTRAL STREET 3 #02-18 WHITE SANDS SINGAPORE 518457',
    },
    {
        ECP: 'CAPTION HOLDINGS PTE LTD - EYE STORY',
        Address: '2 JURONG EAST CENTRAL 1 #01-03A JCUBE SINGAPORE 609731',
    },
    {
        ECP: 'CENTURY OPTICAL (2006)',
        Address: '149 ROCHER ROAD #01-10 FU LU SHOU COMPLEX SINGAPORE 188425',
    },
    {
        ECP: "CHEW'S OPTICS",
        Address: 'BLK 282 BISHAN STREET 22 #01-157 SINGAPORE 570282',
    },
    {
        ECP: "CHEW'S OPTICS",
        Address: '9 YIO CHU KANG ROAD #01-22 SPACE @ KOVAN SINGAPORE 545523',
    },
    {
        ECP: "CHEW'S OPTICS",
        Address: 'BLK 144 TECK WHYE LANE #01-211 SINGAPORE 680144',
    },
    {
        ECP: 'CHIN & CHIN WATCH & OPTICIAN',
        Address: 'BLK 201E TAMPINES STREET 23 #01-80 SINGAPORE 527201',
    },
    {
        ECP: 'CHOICE OPTICAL',
        Address: 'BLK 682 HOUGANG AVENUE 4 #01-320 SINGAPORE 530682',
    },
    {
        ECP: 'CITY EYEHUB',
        Address: 'BLK 631 ANG MO KIO AVENUE 4 #01-920 SINGAPORE 560631',
    },
    {
        ECP: 'CLARITY EYECARE PTE LTD',
        Address: '200 TURF CLUB ROAD #01-22A THE GRANDSTAND SINGAPORE 287994',
    },
    {
        ECP: 'CLARITY EYECARE PTE LTD',
        Address: '118 HOLLAND AVENUE #02-03 RAFFLES HOLLAND V SINGAPORE 278997',
    },
    {
        ECP: 'CLEAR VISION',
        Address: 'BLK 291 YISHUN STREET 22 #01-365 SINGAPORE 760291',
    },
    {
        ECP: 'CLEAR VISION',
        Address: 'BLK 818 CHOA CHU KANG AVENUE 1 #01-11 KEAT HONG MIRAGE SINGAPORE 680818',
    },
    {
        ECP: 'CLEARVIEW OPTICAL',
        Address: '28 DOVER CRESCENT #01-93 MULTI-STOREY CARPARK SINGAPORE 130028',
    },
    {
        ECP: 'CLEMENTI OPTICAL & WATCH CENTRE',
        Address: 'BLK 442 CLEMENTI AVENUE 3 #01-111 SINGAPORE 120442',
    },
    {
        ECP: 'COLUTI VISION PTE LTD',
        Address: '460 ALEXANDRA ROAD #02-02 ALEXANDRA RETAIL CENTRE SINGAPORE 119963',
    },
    {
        ECP: 'CRYSTAL OPTICS (2001) PTE LTD PTE LTD',
        Address: '90 HOUGANG AVENUE 10 #02-07 HOUGANG MALL SINGAPORE 538766',
    },
    {
        ECP: 'CRYSTALITE CONTACT LENS & OPTICAL',
        Address: 'BLK 1 JOO CHIAT ROAD #01-1011 JOO CHIAT COMPLEX SINGAPORE 420001',
    },
    {
        ECP: "D' EYEWEAR LLP",
        Address: '180 BENCOOLEN STREET #01-41 THE BENCOOLEN SINGAPORE 189646',
    },
    {
        ECP: 'D POLIM OPTOMETRIST AND CONTACT LENS CLINIC',
        Address: 'BLK 632 BEDOK RESERVOIR ROAD #01-842 SINGAPORE 470632',
    },
    {
        ECP: 'DE EYEWEAR',
        Address: 'BLK 722 CLEMENTI WEST STREET 2 #01-158 SINGAPORE 120722',
    },
    {
        ECP: 'DESIGNER EYEWEAR & CONTACT LENS CENTRE',
        Address: '231 BAIN STREET #01-47 BRAS BASAH COMPLEX SINGAPORE 180231',
    },
    {
        ECP: 'DG OPTICS CENTRE',
        Address: '414 YISHUN RING ROAD #01-1859 SINGAPORE 760414',
    },
    {
        ECP: 'DK OPTICAL',
        Address: '124 BUKIT MERAH LANE 1 #01-148 ALEXANDRA VILLAGE SINGAPORE 150124',
    },
    {
        ECP: 'DOLCE PURO PTE LTD',
        Address: 'BLK 43 HOLLAND DRIVE #01-67 SINGAPORE 270043',
    },
    {
        ECP: "D'OPTOMETRISTS PTE LTD",
        Address: '11 COLLYER QUAY #02-17 THE ARCADE SINGAPORE 049317',
    },
    {
        ECP: 'DR ALICIA EYE SPECIALIST',
        Address: '6 NAPIER ROAD #05-04 GLENEAGLES MEDICAL CENTRE SINGAPORE 258499',
    },
    {
        ECP: 'DUKE OPTICAL CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 726 ANG MO KIO AVENUE 6 #01-4166 SINGAPORE 560726',
    },
    {
        ECP: 'E & P OPTICS',
        Address: "101 UPPER CROSS STREET #03-19 PEOPLE'S PARK CENTRE SINGAPORE 058357",
    },
    {
        ECP: 'EAGLE EYE CENTRE PTE LTD',
        Address: '38 IRRAWADDY ROAD #08-24 SINGAPORE 329563',
    },
    {
        ECP: 'EAGLE EYE CENTRE PTE LTD',
        Address: '319 JOO CHIAT PLACE #05-03 EAST SHORE MEDICAL CENTRE SINGAPORE 427989',
    },
    {
        ECP: 'EAGLE EYE CENTRE PTE LTD',
        Address: '9 KING ALBERT PARK #01-42/43/44 KAP RESIDENCES MALL SINGAPORE 598332',
    },
    {
        ECP: 'EAGLE EYE CENTRE PTE LTD',
        Address: '820 THOMSON ROAD #06-57/58/59/60/61/62 MT ALVERNIA HOSPITAL MEDICAL CENTRE D SINGAPORE 574623',
    },
    {
        ECP: 'EAGLE EYE CENTRE PTE LTD',
        Address: '3 MOUNT ELIZABETH #08-08 MT ELIZABETH MEDICAL CENTRE SINGAPORE 228510',
    },
    {
        ECP: 'EAGLE EYE CENTRE PTE LTD',
        Address: '101 IRRAWADDY ROAD #11-07/08/09/10/11/12/13 ROYAL SQUARE NOVENA MEDICAL CENTRE SINGAPORE 329565',
    },
    {
        ECP: 'EASTCHAMP (ALEXANDRA) PTE LTD',
        Address: '370 ALEXANDRA ROAD #B1-25 ANCHOR POINT SINGAPORE 159953',
    },
    {
        ECP: 'EDEN EYELAND PTE LTD',
        Address: '6A SHENTON WAY OUE 1 #01-07 DOWNTOWN GALLERY SINGAPORE 068809',
    },
    {
        ECP: 'EE HENG OPTICAL CENTRE',
        Address: 'BLK 153 SERANGOON NORTH AVENUE 1 #01-470 SINGAPORE 550153',
    },
    {
        ECP: 'ELITES OPTICS PTE LTD',
        Address: '301 UPPER THOMSON ROAD #01-10A THOMSON PLAZA SINGAPORE 574408',
    },
    {
        ECP: 'EMINENT EYEWEAR',
        Address: 'BLK 451 ANG MO KIO AVENUE 10 #01-1753 SINGAPORE 560451',
    },
    {
        ECP: 'EMM3 VISIONCARE',
        Address: '1 MARITIME SQUARE #02-63 HARBOURFRONT CENTRE SINGAPORE 099253',
    },
    {
        ECP: 'ETAL VISION PTE LTD',
        Address: '1 IRVING PLACE #08-01 THE COMMERZE @ IRVING SINGAPORE 369546',
    },
    {
        ECP: 'EVERBRIGHT OPTICAL CO PTE LTD',
        Address: 'BLK 210 NEW UPPER CHANGI ROAD #01-719 SINGAPORE 460210',
    },
    {
        ECP: 'EVERFAITH OPTICS',
        Address: '264 SERANGOON CENTRAL #01-223 BRADDELL HEIGHTS COMMUNITY HUB SINGAPORE 550264',
    },
    {
        ECP: 'EVERSHARP OPTICAL HOUSE',
        Address: '360 ORCHARD ROAD #01-11 INTERNATIONAL BUILDING SINGAPORE 238869',
    },
    {
        ECP: 'EYE & RETINA SURGEONS',
        Address: '1 ORCHARD BOULEVARD #13-03/04/05 CAMDEN MEDICAL CENTRE SINGAPORE 248649',
    },
    {
        ECP: 'EYE AND I OPTICAL CENTRE',
        Address: 'BLK 410 ANG MO KIO AVENUE 10 #01-849 SINGAPORE 560410',
    },
    {
        ECP: 'EYE BAR OPTICS',
        Address: '17 PETIR ROAD #B1-55/56 SINGAPORE 678278',
    },
    {
        ECP: 'EYE BRACES CLINIC PTE LTD',
        Address: '55 SIGLAP ROAD #B1-18 SIGLAP CENTRE SINGAPORE 455871',
    },
    {
        ECP: 'EYE DO VISION',
        Address: 'BLK 633 BUKIT BATOK CENTRAL #01-128 SINGAPORE 650633',
    },
    {
        ECP: 'EYE ESSENTIALS',
        Address: '1 QUEENSWAY ROAD #02-31 QUEENSWAY SHOPPING CENTRE SINGAPORE 149053',
    },
    {
        ECP: 'EYE GEN PTE LTD',
        Address: '153 SERANGOON NORTH AVENUE 1 #01-528 SINGAPORE 550153',
    },
    {
        ECP: 'EYE HUB',
        Address: 'BLK 144 TAMPINES STREET 12 #01-394 SINGAPORE 521144',
    },
    {
        ECP: 'EYE LAB',
        Address: 'BLK 186 TOA PAYOH CENTRAL #01-438 SINGAPORE 310186',
    },
    {
        ECP: 'EYE MASTER PTE LTD',
        Address: '154 WEST COAST ROAD #01-44 WEST COAST PLAZA SINGAPORE 127371',
    },
    {
        ECP: 'EYE SPECIALIST CLINIC PTE LTD',
        Address: '290 ORCHARD ROAD #06-01/05 PARAGON SINGAPORE 238859',
    },
    {
        ECP: 'EYE SPECTRUM',
        Address: '5 UPPER BOON KENG ROAD #01-16 OPP KALLANG MRT SINGAPORE 380005',
    },
    {
        ECP: 'EYE STORY',
        Address: '70 COMPASSVALE BOW #B1-10 SINGAPORE 544692',
    },
    {
        ECP: 'EYE SURGEONS PTE LTD',
        Address: '38 IRRAWADDY ROAD #09-28 SINGAPORE 329563',
    },
    {
        ECP: 'EYE THEORY PTE LTD',
        Address: '5 STADIUM WALK #01-18/19 LEISURE PARK KALLANG SINGAPORE 397693',
    },
    {
        ECP: 'EYE THEORY PTE LTD',
        Address: '20 AH HOOD ROAD #01-07 ZHONGSAN MALL SINGAPORE 329984',
    },
    {
        ECP: 'EYE V OPTICS',
        Address: '587 BUKIT TIMAH ROAD #02-26 CORONATION SHOPPING PLAZA SINGAPORE 269707',
    },
    {
        ECP: 'EYE WERKS PTE LTD',
        Address: '390 ORCHARD ROAD #01-05 PALAIS RENAISSANCE SINGAPORE 238871',
    },
    {
        ECP: 'EYE ZONE OPTICAL PTE LTD',
        Address: 'BLK 213 BEDOK NORTH STREET 1 #01-127 SINGAPORE 460213',
    },
    {
        ECP: 'EYE ZONE OPTICAL PTE LTD',
        Address: 'BLK 184 TOA PAYOH CENTRAL #01-366 SINGAPORE 310184',
    },
    {
        ECP: 'EYE ZONE OPTICAL PTE LTD',
        Address: 'BLK 143 TECK WHYE LANE #01-233 SINGAPORE 680143',
    },
    {
        ECP: 'EYE ZONE OPTICAL PTE LTD',
        Address: 'BLK 301 WOODLANDS STREET 31 #01-259 SINGAPORE 730301',
    },
    {
        ECP: 'EYE ZONE OPTICAL PTE LTD',
        Address: 'BLK 101 YISHUN AVENUE 5 #01-97 CHONG PANG SINGAPORE 760101',
    },
    {
        ECP: 'EYECANDY OPTICAL PTE LTD',
        Address: '437 FERNVALE ROAD #01-04A MULTI-STOREY CARPARK SINGAPORE 790437',
    },
    {
        ECP: 'EYECARE 24/7',
        Address: "101 UPPER CROSS STREET #02-18 PEOPLE'S PARK CENTRE SINGAPORE 058357",
    },
    {
        ECP: 'EYECARE 24/7',
        Address: '681 PUNGGOL DRIVE #01-18 OASIS TERRACES SINGAPORE 820681',
    },
    {
        ECP: 'EYECARE HOUSE PTE LTD',
        Address: '14 SCOTTS ROAD #01-51 FAR EAST PLAZA SINGAPORE 228213',
    },
    {
        ECP: 'EYECARE II PTE LTD',
        Address: '33 SENGKANG WEST AVENUE #01-25 THE SELETAR MALL SINGAPORE 797653',
    },
    {
        ECP: 'EYECARE III PTE LTD',
        Address: '23 SERANGOON CENTRAL #04-58 NEX SINGAPORE 556083',
    },
    {
        ECP: 'EYECARE IV PTE LTD',
        Address: '11 BIDADARI PARK DRIVE #01-22/23 THE WOODLEIGH MALL SINGAPORE 367803',
    },
    {
        ECP: 'EYECARE PEOPLE PTE LTD',
        Address: '101 IRRAWADDY ROAD #20-10 ROYAL SQUARE SINGAPORE 329565',
    },
    {
        ECP: 'EYECARE SPECIALIST PTE LTD',
        Address: '83 MARINE PARADE ROAD #01-562 PARKWAY PARADE SINGAPORE 440083',
    },
    {
        ECP: 'EYECARE STUDIO',
        Address: '63 JURONG WEST CENTRAL 3 #01-13 JURONG POINT 2 SINGAPORE 648331',
    },
    {
        ECP: 'EYECHAMP OPTOMETRISTS',
        Address: '61 ANG MO KIO AVENUE 8 #01-11/12 JUBILEE SQUARE SINGAPORE 569814',
    },
    {
        ECP: 'EYECHAMP OPTOMETRISTS',
        Address: '23 SERANGOON CENTRAL #B1-50/51 NEX SINGAPORE 556083',
    },
    {
        ECP: 'EYECHAMP YOUNG',
        Address: '83 PUNGGOL CENTRAL #02-31 WATERWAY POINT SINGAPORE 828761',
    },
    {
        ECP: 'EYEDENTITY VISIONCARE PTE LTD',
        Address: '10 PAYA LEBAR ROAD #01-12/13 PAYA LEBAR QUARTER SINGAPORE 409057',
    },
    {
        ECP: 'EYEDENTITY VISIONCARE PTE LTD',
        Address: '50 JURONG GATEWAY ROAD #01-52 JEM SINGAPORE 608549',
    },
    {
        ECP: 'EYEDENTITY VISIONCARE PTE LTD',
        Address: '3 TEMASEK BOULEVARD #02-426 SUNTEC CITY MALL TOWER 5 SINGAPORE 038983',
    },
    {
        ECP: 'EYE-DO-OPTIC PTE LTD',
        Address: 'BLK 449 ANG MO KIO AVENUE 10 #01-1725 SINGAPORE 560449',
    },
    {
        ECP: 'EYE-DO-OPTIC PTE LTD',
        Address: '1 JURONG WEST CENTRAL 2 #01-09/10 JP1 JURONG POINT SINGAPORE 648886',
    },
    {
        ECP: 'EYE-DO-OPTIC PTE LTD',
        Address: 'BLK 205 HOUGANG STREET 21 #02-24/25 HEARTLAND MALL SINGAPORE 530205',
    },
    {
        ECP: 'EYE-DO-OPTIC PTE LTD',
        Address: '23 SERANGOON CENTRAL #B1-70 NEX SINGAPORE 556083',
    },
    {
        ECP: 'EYE-DO-OPTIC PTE LTD',
        Address: '51 UPPER SERANGOON ROAD THE POIZ CENTRE #01-05 SINGAPORE 347697',
    },
    {
        ECP: 'EYEGLASS POINT',
        Address: 'BLK 152 SERANGOON NORTH AVENUE 1 #01-302 SINGAPORE 550152',
    },
    {
        ECP: 'EYELINK PTE LTD',
        Address: '1 BUKIT BATOK CENTRAL LINK #02-03 WEST MALL SINGAPORE 658713',
    },
    {
        ECP: 'EYENET',
        Address: 'BLK 272 BUKIT BATOK AVENUE 4 #01-70 SINGAPORE 650272',
    },
    {
        ECP: 'EYES @ WORK PTE LTD',
        Address: '9 RAFFLES BOULEVARD #01-38 MILLENIA WALK SINGAPORE 039596',
    },
    {
        ECP: 'EYES 2 EYES',
        Address: '1 PASIR RIS CLOSE #02-328 E! AVENUE SINGAPORE 519599',
    },
    {
        ECP: 'EYES 2 EYES',
        Address: 'BLK 248 SIMEI STREET 3 #01-108 SINGAPORE 520248',
    },
    {
        ECP: 'EYES FUSION OPTOMETRISTS',
        Address: '10 ANSON ROAD #01-71 INTERNATIONAL PLAZA SINGAPORE 079903',
    },
    {
        ECP: 'EYES PALACE PTE LTD',
        Address: '180 BENCOOLEN STREET #01-96 THE BENCOOLEN BUILDING SINGAPORE 189646',
    },
    {
        ECP: "EYE'S VISION & OPTICS",
        Address: 'BLK 59 NEW UPPER CHANGI ROAD #01-1236 SINGAPORE 461059',
    },
    {
        ECP: "EYE'S VISION & OPTICS",
        Address: 'BLK 113 ALJUNIED #01-31 SINGAPORE 389113',
    },
    {
        ECP: "EYE'S VISION & OPTICS",
        Address: 'BLK 86 BEDOK NORTH STREET 4 #01-173 SINGAPORE 460086',
    },
    {
        ECP: 'EYESIGHT CONSULTANCY PTE LTD',
        Address: 'BLK 148 POTONG PASIR AVENUE 1 #01-51 SINGAPORE 350148',
    },
    {
        ECP: 'EYESIGHT EYEWEAR PTE LTD',
        Address: '1 BUKIT BATOK CENTRAL LINK #04-05 WEST MALL SINGAPORE 658713',
    },
    {
        ECP: 'EYESIGHT.SG',
        Address: '238B THOMSON ROAD #05-01-08 NOVENA SQUARE SINGAPORE 370685',
    },
    {
        ECP: 'EYESMATE',
        Address: 'BLK 503 TAMPINES CENTRAL 1 #01-305 SINGAPORE 520503',
    },
    {
        ECP: 'EYESMILE OPTICAL',
        Address: '10 SENGKANG SQUARE #01-114 KOPITIAM SQUARE SINGAPORE 544829',
    },
    {
        ECP: 'EYEVENTURE PTE LTD',
        Address: '9 BISHAN PLACE #B1-15 JUNCTION 8 SINGAPORE 579837',
    },
    {
        ECP: 'EYEWEAR HOUSE',
        Address: '9 BISHAN PLACE #01-15 JUNCTION 8 SINGAPORE 579837',
    },
    {
        ECP: 'EYEWEAR OPTICS PTE LTD',
        Address: '301 UPPER THOMSON ROAD #01-66 THOMSON PLAZA SINGAPORE 574408',
    },
    {
        ECP: 'EYEWEAR TALENT LLP',
        Address: '414 YISHUN RING ROAD #01-1867 SINGAPORE 760414',
    },
    {
        ECP: 'EYEZVISION LLP',
        Address: 'BLK 476 UPPER SERANGOON VIEW #02-10 SINGAPORE 530476',
    },
    {
        ECP: 'FACE OPTICS',
        Address: 'BLK 746 BEDOK RESERVOIR #01-3001 SINGAPORE 470746',
    },
    {
        ECP: 'FACE-MATCH OPTICAL PTE LTD',
        Address: '1 SCOTTS ROAD #02-21/22 SHAW CENTRE SINGAPORE 228208',
    },
    {
        ECP: 'FACEVISION',
        Address: 'BLK 417 FERNVALE LINK #01-08 SINGAPORE 790417',
    },
    {
        ECP: 'FENG YANG EYECARE',
        Address: 'BLK 963 JURONG WEST STREET 91 #01-312 SINGAPORE 640963',
    },
    {
        ECP: 'FIRST CONTACT LENS CENTRE',
        Address: '301 UPPER THOMSON ROAD #01-63/64 THOMSON PLAZA SINGAPORE 574408',
    },
    {
        ECP: 'FIRST EYECARE CENTRE PTE LTD',
        Address: '14 SCOTTS ROAD #03-72 FAR EAST PLAZA SINGAPORE 228213',
    },
    {
        ECP: 'FIRST EYEWEAR CENTRE PTE LTD',
        Address: '713 EAST COAST ROAD SINGAPORE 459066',
    },
    {
        ECP: 'FLO OPTICS PTE LTD',
        Address: '200 VICTORIA STREET #01-64 PARCO BUGIS JUNCTION SINGAPORE 188021',
    },
    {
        ECP: 'FLO OPTICS PTE LTD',
        Address: '20 ANSON ROAD #11-01 TWENTY ANSON SINGAPORE 079912',
    },
    {
        ECP: 'INFINITY EYEWEAR PTE LIMITED',
        Address: 'BLK 101 TOWNER ROAD #01-232 SINGAPORE 322101',
    },
    {
        ECP: 'INFINITY EYEWEAR PTE LIMITED',
        Address: 'BLK 78B TELOK BLANGAH STREET 32 #01-16 SINGAPORE 102078',
    },
    {
        ECP: 'SINMA OPTICAL CO PTE LTD',
        Address: '1 QUEENSWAY ROAD #02-35 QUEENSWAY SHOPPING CENTRE SINGAPORE 149053',
    },
    {
        ECP: 'SINTAT OPTICAL CENTRE',
        Address: '865 MOUNTBATTEN ROAD #B1-76 KATONG SHOPPING CENTRE SINGAPORE 437844',
    },
    {
        ECP: 'SNEC EYE CLINIC @ BEDOK',
        Address: 'BLK 212 BEDOK NORTH STREET 1 #03-147 SINGAPORE 460212',
    },
    {
        ECP: 'FLO OPTICS PTE LTD',
        Address: '8A MARINA BOULEVARD #B2-08 MARINA BAY LINK MALL SINGAPORE 018984',
    },
    {
        ECP: 'FLO OPTICS PTE LTD',
        Address: '302 TIONG BAHRU ROAD #01-134 TIONG BAHRU PLAZA SINGAPORE 168732',
    },
    {
        ECP: 'FLO OPTICS PTE LTD',
        Address: "21 CHOA CHU KANG AVENUE 4 #02-14 LOT ONE SHOPPERS' MALL SINGAPORE 689812",
    },
    {
        ECP: 'FLO OPTICS PTE LTD',
        Address: '80 MARINE PARADE ROAD #B1-71/73 PARKWAY PARADE SINGAPORE 449269',
    },
    {
        ECP: 'FOCAL EYE CENTRE PTE LTD',
        Address: '38 IRRAWADDY ROAD #10-21 MT ELIZABETH NOVENA SPECIALIST CENTRE SINGAPORE 329563',
    },
    {
        ECP: 'FOCAL OPTICS & TRADING',
        Address: 'BLK 6 EUNOS CRESCENT #01-2645 SINGAPORE 400006',
    },
    {
        ECP: 'FORESIGHT CONTACT LENSES OPTICAL CENTRE',
        Address: 'BLK 190 TOA PAYOH CENTRAL #01-544 SINGAPORE 310190',
    },
    {
        ECP: 'FRAMEWERKS OPTICAL PRIVATE LIMITED',
        Address: 'BLK 824 TAMPINES STREET 81 #01-24 SINGAPORE 520824',
    },
    {
        ECP: 'G OPTICAL PTE LTD',
        Address: '12 BEDOK NORTH DRIVE #02-06 SINGAPORE 465492',
    },
    {
        ECP: 'G OPTICAL PTE LTD',
        Address: '150 TOA PAYOH LOR 1 #01-989 SINGAPORE 310150',
    },
    {
        ECP: 'GO EYECARE PTE LTD',
        Address: 'BLK 443 PASIR RIS DRIVE 6 #01-18 SINGAPORE 510443',
    },
    {
        ECP: 'GO EYECARE PTE LTD',
        Address: 'BLK 201E TAMPINES STREET 23 #01-90 SINGAPORE 527201',
    },
    {
        ECP: 'GO EYECARE PTE LTD',
        Address: 'BLK 417 YISHUN AVENUE 11 #01-329 SINGAPORE 760417',
    },
    {
        ECP: 'GO EYECARE PTE LTD',
        Address: 'BLK 846 YISHUN RING ROAD #01-3625 SINGAPORE 760846',
    },
    // {
    // ECP: 'GRAND OPTICAL & CONTACT LENS PTE LTD',
    // Address: 'BLK 190 TOA PAYOH CENTRAL #01-568 SINGAPORE 310190',
    // },
    // {
    // ECP: 'GRAND OPTICAL & CONTACT LENS PTE LTD',
    // Address: 'BLK 633 BUKIT BATOK CENTRAL #01-124 SINGAPORE 650633',
    // },
    // {
    // ECP: 'GRAND OPTICAL & CONTACT LENS PTE LTD',
    // Address: 'BLK 131 JURONG EAST STREET 13 #01-245 SINGAPORE 600131',
    // },
    {
        ECP: 'HENG KWANG OPTICS AND CONTACT LENS CENTRE',
        Address: '180 BENCOOLEN STREET #01-30 THE BENCOOLEN SINGAPORE 189969',
    },
    {
        ECP: 'INFINITY OPTICAL - TESSENSOHN',
        Address: '683 TESSENSOHN ROAD #01-133 SINGAPORE 210683',
    },
    {
        ECP: 'I-OBSERVATION HOUSE PTE LTD',
        Address: 'NO 1 HOUGANG STREET 91 #01-11 HOUGANG 1 SINGAPORE 538692',
    },
    {
        ECP: "I'S CONCOCTIONS",
        Address: 'BLK 2A NO 2443 EUNOS CRESCENT SINGAPORE 401002',
    },
    {
        ECP: 'IWEAR.COM',
        Address: 'BLK 721 CLEMENTI WEST STREET 2 #01-124 SINGAPORE 120721',
    },
    {
        ECP: 'J GLASSES PTE LTD',
        Address: '10 PAYA LEBAR ROAD #03-17 PAYA LEBAR QUARTER MALL SINGAPORE 409057',
    },
    {
        ECP: 'J S OPTICAL',
        Address: 'BLK 106 HOUGANG AVENUE 1 #01-1205 SINGAPORE 530106',
    },
    {
        ECP: 'JAMCO OPTICAL & TRADING CO',
        Address: '865 MOUNTBATTEN ROAD #B1-15 KATONG SHOPPING CENTRE SINGAPORE 437844',
    },
    {
        ECP: "JAME'S KOH OPTICAL & WATCHES",
        Address: 'BLK 253 JURONG EAST STREET 24 #01-229 SINGAPORE 600253',
    },
    {
        ECP: 'JEN JEN OPTICAL WATCH & CONTACT LENS CENTRE',
        Address: 'BLK 160 YISHUN STREET 11 #01-202 SINGAPORE 760160',
    },
    {
        ECP: 'JERRY TAN EYE SURGERY PTE LTD',
        Address: '1 SCOTTS ROAD #21-03 SHAW CENTRE SINGAPORE 228208',
    },
    {
        ECP: "J'S OPTICS",
        Address: 'BLK 503 JURONG WEST STREET 52 #01-837 SINGAPORE 640503',
    },
    {
        ECP: 'JUN HANG OPTICAL & TRADING',
        Address: 'BLK 141 TECK WHYE LANE #01-251 SINGAPORE 680141',
    },
    {
        ECP: 'JUNE OPTICS AND CONTACT LENSES',
        Address: '228 CHANGI ROAD #B1-08 ICON@CHANGI SINGAPORE 419741',
    },
    {
        ECP: 'JUST EYES',
        Address: '10 ANSON ROAD #02-50 INTERNATIONAL PLAZA SINGAPORE 079903',
    },
    {
        ECP: 'KAI JOO DEPARTMENTAL AND OPTICAL',
        Address: '1 TANJONG PAGAR PLAZA #01-12 SINGAPORE 082001',
    },
    {
        ECP: 'KAI JOO HUNG OPTICAL & WATCHES',
        Address: 'BLK 201E TAMPINES STREET 23 #01-62 SINGAPORE 527201',
    },
    {
        ECP: 'KAI JOO OPTICAL',
        Address: '90 HOUGANG AVENUE 10 #02-16 HOUGANG MALL SINGAPORE 538766',
    },
    {
        ECP: 'KAI JOO OPTOMETRY',
        Address: '3 SIMEI STREET 6 #01-30 SINGAPORE 528833',
    },
    {
        ECP: 'KALLANG BAHRU OPTICAL AND CONTACT LENS CENTRE',
        Address: 'BLK 66 KALLANG BAHRU #01-503 SINGAPORE 330066',
    },
    {
        ECP: 'KANDO OPTICS',
        Address: '1 JALAN ANAK BUKIT #01-12 BUKIT TIMAH PLAZA SINGAPORE 588996',
    },
    {
        ECP: 'HAZEL EYECARE',
        Address: 'BLK 505C BISHAN STREET 11 #01-412 SINGAPORE 573505',
    },
    {
        ECP: 'KIMPO-DO (S) PTE LTD',
        Address: '8 KALLANG AVENUE #06-09 APERIA TOWER ONE SINGAPORE 339509',
    },
    {
        ECP: 'KIMPO-DO (S) PTE LTD',
        Address: '350 ORCHARD ROAD ISETAN SCOTTS LEVEL 1 SHAW HOUSE SINGAPORE 238868',
    },
    {
        ECP: 'HIROCON PTE LTD',
        Address: '678A WOODLANDS AVENUE 6 #01-17 SINGAPORE 731678',
    },
    {
        ECP: 'HIROCON PTE LTD',
        Address: '1 RAFFLES PLACE #03-26 ONE RAFFLES PLACE SINGAPORE 048616',
    },
    {
        ECP: 'HIROCON PTE LTD',
        Address: '277 ORCHARD ROAD #B2-28 ORCHARD GATEWAY SINGAPORE 238858',
    },
    {
        ECP: 'HONOUR OPTICAL & CONTACT LENSES CENTRE',
        Address: 'BLK 925 YISHUN CENTRAL 1 #01-213 SINGAPORE 760925',
    },
    {
        ECP: 'HWA KWONG OPTICAL & WATCH CONTACT LENS CENTRE',
        Address: 'BLK 74 WHAMPOA DRIVE #01-320 SINGAPORE 320074',
    },
    {
        ECP: 'I C U EYE CARE UNIT',
        Address: '160 ROBINSON ROAD #03-05 SBF CENTER SBF MEDICAL SUITE SINGAPORE 068914',
    },
    {
        ECP: 'I OPTICS STUDIO',
        Address: '377 HOUGANG STREET 32 #01-05 SINGAPORE 530377',
    },
    {
        ECP: 'IGARD PRIVATE LIMITED',
        Address: '51 CUPPAGE ROAD #01-04 STARHUB CENTRE SINGAPORE 229469',
    },
    {
        ECP: 'ILOOK OPTICS',
        Address: 'BLK 735 PASIR RIS STREET 72 #01-350 PASIR RIS WEST PLAZA SINGAPORE 510735',
    },
    {
        ECP: 'KJ OPTOMETRISTS PTE LTD',
        Address: '1 WOODLANDS ROAD #01-19 JUNCTION 10 SINGAPORE 677899',
    },
    {
        ECP: 'KJ OPTOMETRISTS PTE LTD',
        Address: '10 EUNOS ROAD 8 #B1-121 SINGPOST CENTRE SINGAPORE 408600',
    },
    {
        ECP: 'KJ OPTOMETRISTS PTE LTD',
        Address: '68 ORCHARD ROAD #04-52 PLAZA SINGAPURA SINGAPORE 238839',
    },
    {
        ECP: 'HOLLAND OPTICAL CO',
        Address: '211 HOLLAND AVENUE #02-09 HOLLAND RD SHOPPING CENTRE SINGAPORE 278967',
    },
    {
        ECP: 'KLEARER VISION',
        Address: 'BLK 214 BEDOK NORTH STREET 1 #01-163 SINGAPORE 460214',
    },
    {
        ECP: 'KOZO LP',
        Address: '304 ORCHARD ROAD #B1-105 LUCKY PLAZA SINGAPORE 238863',
    },
    {
        ECP: 'KWONG SHIN EYEWEAR PTE LTD',
        Address: '231 BAIN STREET #01-43 BRAS BASAH COMPLEX SINGAPORE 180231',
    },
    {
        ECP: 'LEE MENG EYEWEAR TRADING CENTRE',
        Address: '1 QUEENSWAY ROAD #01-47 QUEENSWAY SHOPPING CENTRE SINGAPORE 149053',
    },
    {
        ECP: 'LEGION EYECARE',
        Address: 'BLK 501 JURONG WEST STREET 51 #01-263 SINGAPORE 640501',
    },
    {
        ECP: 'LENS HUB BY LEE HENG GROUP',
        Address: '88 TANGLIN HALT ROAD #01-05 SINGAPORE 141088',
    },
    {
        ECP: 'LENS VISION PTE LTD',
        Address: '168 PUNGGOL FIELD #02-03 SINGAPORE 820168',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '1 RAFFLES LINK #B1-42A CITYLINK MALL SINGAPORE 039393',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '90 HOUGANG AVENUE 10 #01-12/13 HOUGANG MALL SINGAPORE 538766',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '2 JURONG EAST STREET 21 #01-135 IMM BUILDING SINGAPORE 609601',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '9 BISHAN PLACE #02-22/23 JUNCTION 8 SINGAPORE 579837',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '2 KAKI BUKIT AVENUE 1 #04-04 SINGAPORE 417938',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '930 YISHUN AVENUE 2 #01-106 NORTHPOINT CITY SINGAPORE 769098',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '60 PIONEER ROAD LENSKART SGP WAREHOUSE SINGAPORE 628509',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '10 PAYA LEBAR ROAD #B1-30 PAYA LEBAR QUARTER SINGAPORE 409057',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '80 MARINE PARADE ROAD #B1-99/100 PARKWAY PARADE SINGAPORE 449269',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '10 TAMPINES CENTRAL 1 #01-36 TAMPINES 1 SINGAPORE 529536',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '1 HARBOURFRONT WALK #02-238 VIVO CITY SINGAPORE 098585',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '30 CECIL STREET #19-08 PRUDENTIAL TOWER SINGAPORE 049712',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '21 CHOA CHU KANG AVENUE 4 #01-19 LOT 1 SINGAPORE 689812',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '2 TAMPINES CENTRAL 5 #01-02 CENTURY SQUARE SINGAPORE 529509',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '107 NORTH BRIDGE ROAD #B1-08/09 FUNAN MALL SINGAPORE 179105',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '1 JURONG WEST CENTRAL 2 #01-05 JURONG POINT SINGAPORE 648886',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '435 ORCHARD ROAD #B1-15 WISMA ATRIA SINGAPORE 238877',
    },
    {
        ECP: 'LENSKART SOLUTION PTE LTD',
        Address: '83 PUNGGOL CENTRAL #01-06 WATERWAY POINT SINGAPORE 828761',
    },
    {
        ECP: 'LEONG YEW OPTICAL CO',
        Address: 'BLK 631 BEDOK RESERVOIR ROAD #01-956 SINGAPORE 470631',
    },
    {
        ECP: 'LIANG YEW CONTACT LENS OPTICAL & WATCHES CENTRE',
        Address: 'BLK 821 TAMPINES STREET 81 #01-222 SINGAPORE 520821',
    },
    {
        ECP: 'LIFETIME VISION CARE PTE LTD',
        Address: '101 THOMSON ROAD #01-84 UNITED SQUARE SINGAPORE 307591',
    },
    {
        ECP: 'LSC EYE CLINIC PTE LTD',
        Address: '290 ORCHARD ROAD #16-01/02 PARAGON SINGAPORE 238859',
    },
    {
        ECP: 'LUCID EYECARE PTE LTD',
        Address: '11 TANJONG KATONG ROAD #01-22/23 KINEX SINGAPORE 437157',
    },
    {
        ECP: 'LUMIN EYE SPECIALISTS',
        Address: '6A NAPIER ROAD GLENEAGLES HOSPITAL #02-36A/B HOSPITAL ANNEXE SINGAPORE 258500',
    },
    {
        ECP: 'MAGICAL EYE CARE',
        Address: 'BLK 123 BUKIT MERAH LANE 1 #01-120 SINGAPORE 150123',
    },
    {
        ECP: 'MANCHESTER EYEWEAR PTE LTD',
        Address: '30 SEMBAWANG DRIVE #01-04 SUN PLAZA SINGAPORE 757713',
    },
    {
        ECP: 'MANCHESTER EYEWEAR PTE LTD',
        Address: '30 WOODLANDS AVENUE 1 #01-03 THE WOODGROVE SINGAPORE 739065',
    },
    {
        ECP: 'MEE MEE PTE LTD',
        Address: '313 ORCHARD ROAD #04-03 313@SOMERSET SINGAPORE 238895',
    },
    {
        ECP: 'MEE MEE PTE LTD',
        Address: '1 JALAN ANAK BUKIT #01-16B BUKIT TIMAH PLAZA SINGAPORE 588996',
    },
    {
        ECP: 'MEI LIM OPTICAL',
        Address: 'BLK 210 HOUGANG STREET 21 #01-235 SINGAPORE 530210',
    },
    {
        ECP: 'MEI TECK OPTICAL HOUSE',
        Address: '175 BENCOOLEN STREET #01-40 BURLINGTON SQUARE SINGAPORE 189649',
    },
    {
        ECP: 'MELOCK OPTICAL CO',
        Address: 'BLK 139 TAMPINES STREET 11 #01-82 SINGAPORE 521139',
    },
    {
        ECP: 'MING MING OPTICAL COMPANY',
        Address: '1 JALAN ANAK BUKIT #01-05 BUKIT TIMAH PLAZA SINGAPORE 588996',
    },
    {
        ECP: 'MING OPTIQUE PTE LTD',
        Address: 'BLK 58 MARINE TERRACE #01-45 SINGAPORE 440058',
    },
    {
        ECP: 'MIRAR LABORATORIES PTE LTD',
        Address: '1 CLEMENTI LOOP #05-01 SINGAPORE 129808',
    },
    {
        ECP: 'M-SPECS OPTICAL',
        Address: 'BLK 21 GHIM MOH ROAD #01-163 SINGAPORE 270021',
    },
    {
        ECP: 'MY EYE ROOM PTE LTD',
        Address: '484 UPPER BUKIT TIMAH ROAD #01-01 SINGAPORE 678087',
    },
    {
        ECP: 'MY EYES',
        Address: '252 JURONG EAST STREET 24 #01-141 YUHUA VILLAGE SINGAPORE 600252',
    },
    {
        ECP: 'MY OPTICS',
        Address: '190 MIDDLE ROAD #01-27 FORTUNE CENTRE SINGAPORE 188979',
    },
    {
        ECP: 'MYOPTIX PTE LTD',
        Address: '1 ORCHARD BOULEVARD #12-01 CAMDEN MEDICAL CENTRE SINGAPORE 248649',
    },
    {
        ECP: 'MYOPTIX PTE LTD',
        Address: 'BLK 103 YISHUN RING ROAD #01-91 SINGAPORE 760103',
    },
    {
        ECP: 'NAN FANG OPTOMETRISTS PTE LTD',
        Address: 'BLK 237 SERANGOON AVENUE 3 #01-122 SINGAPORE 550237',
    },
    {
        ECP: 'NANYANG OPTICAL CO PTE LTD',
        Address: '31 TANNERY LANE #06-01 HB CENTRE II SINGAPORE 347788',
    },
    {
        ECP: 'NANYANG OPTICAL CO PTE LTD',
        Address: '1 WOODLANDS SQUARE #02-20 CAUSEWAY POINT SINGAPORE 738099',
    },
    {
        ECP: 'NANYANG OPTICAL CO PTE LTD',
        Address: '1 PASIR RIS CLOSE #01-330 DOWNTOWN EAST SINGAPORE 519599',
    },
    {
        ECP: 'NANYANG OPTICAL CO PTE LTD',
        Address: '63 JURONG WEST CENTRAL 3 #B1-35 JURONG POINT SINGAPORE 648886',
    },
    {
        ECP: 'NANYANG OPTICAL CO PTE LTD',
        Address: '80 MARINE PARADE ROAD #B1-60 PARKWAY PARADE SINGAPORE 449269',
    },
    {
        ECP: 'NATASHA LIM EYE CENTRE',
        Address: '101 IRRAWADDY ROAD #19-01/02 ROYAL SQUARE MEDICAL CENTRE SINGAPORE 329565',
    },
    {
        ECP: 'NATION OPTICAL',
        Address: 'BLK 629 ANG MO KIO AVENUE 4 #01-1002 SINGAPORE 560629',
    },
    {
        ECP: 'NATIONAL UNIVERSITY HOSPITAL (SINGAPORE) PTE LTD',
        Address: '1E KENT RIDGE ROAD #13-00 SINGAPORE 119228',
    },
    {
        ECP: 'NEAT OPTICS',
        Address: 'BLK 809 HOUGANG CENTRAL #01-170 SINGAPORE 530809',
    },
    {
        ECP: 'NEW CHINA OPTICIANS PTE LTD',
        Address: '3 COLEMAN STREET #01-10/11 PENINSULA SHOPPING COMPLEX SINGAPORE 179804',
    },
    {
        ECP: 'NEW OPTOMETRY AND OCULAR CARE CENTRE PTE LTD',
        Address: '775 GEYLANG ROAD SINGAPORE 389668',
    },
    {
        ECP: 'NEWMAN OPTICS PTE LTD',
        Address: '89 BEDOK NORTH STREET 4 #01-105 SINGAPORE 460089',
    },
    {
        ECP: 'NGEE ANN POLYTECHNIC',
        Address: '535 CLEMENTI ROAD BLK 81, #01-03 SCHOOL OF HEALTH SCIENCES SINGAPORE 599489',
    },
    {
        ECP: 'NGEE ANN POLYTECHNIC',
        Address: '535 CLEMENTI ROAD BLK 6 LEVEL 1 NGEE ANN POLY OPTOMETRY CENTRE SINGAPORE 599489',
    },
    {
        ECP: 'NICE VISION OPTICAL',
        Address: 'BLK 259 PASIR RIS STREET 21 #02-36 LOYANG POINT SINGAPORE 510259',
    },
    {
        ECP: 'NORTHERN GROUP PTE LTD',
        Address: 'BLK 331 WOODLANDS AVENUE 1 #09-409 SINGAPORE 730331',
    },
    {
        ECP: 'NORTHERN OPTICIANS PTE LTD',
        Address: '1 JELEBU ROAD #02-40/41 BUKIT PANJANG PLAZA SINGAPORE 677743',
    },
    {
        ECP: 'NORTHERN OPTICIANS PTE LTD',
        Address: '290 ORCHARD ROAD #04-20 PARAGON SINGAPORE 238859',
    },
    {
        ECP: 'O+ STAR PTE LTD',
        Address: '1 VISTA EXCHANGE GREEN #01-11 STAR VISTA SINGAPORE 138617',
    },
    {
        ECP: 'OBLIQUE OPTICS PTE LTD',
        Address: '181 ORCHARD ROAD #04-03 ORCHARD CENTRAL SINGAPORE 238896',
    },
    {
        ECP: 'OJO OPTOMETRIST',
        Address: '80 MARINE PARADE ROAD #B1-26/27 PARKWAY PARADE SINGAPORE 449269',
    },
    {
        ECP: 'OJO OPTOMETRY',
        Address: '10 TAMPINES CENTRAL 1 #03-01 SINGAPORE 529536',
    },
    {
        ECP: 'OJO VISION CARE',
        Address: '11 BIDADARI PARK DRIVE #B1-30 WOODLEIGH MALL SINGAPORE 367803',
    },
    {
        ECP: 'ONEVISION TEST CUSTOMER SG',
        Address: '8 TEMASEK BOULEVARD #19-03 SUNTEC TOWER 3 SINGAPORE 38988',
    },
    {
        ECP: "ONG'S OPTICS",
        Address: 'BLK 498 JURONG WEST STREET 41 #01-468 SINGAPORE 640498',
    },
    {
        ECP: 'OPTIC DE OCEAN PTE LTD',
        Address: '80 GENTING LANE #04-05 RUBY INDUSTRIAL COMPLEX SINGAPORE 349565',
    },
    {
        ECP: 'OPTIC FOCUS',
        Address: 'BLK 183 TOA PAYOH CENTRAL #01-308 SINGAPORE 310183',
    },
    {
        ECP: 'OPTIC POINT - THE EYECARE CENTRE',
        Address: '360 ORCHARD ROAD #01-02 INTERNATIONAL BUILDING SINGAPORE 238869',
    },
    {
        ECP: 'OPTICAL 11',
        Address: '417 YISHUN AVENUE 11 #01-337 SINGAPORE 760417',
    },
    {
        ECP: 'OPTICAL 88 (S) PTE LTD',
        Address: '63 UBI AVENUE 1 #06-04 SINGAPORE 408937',
    },
    {
        ECP: 'OPTICAL 88 (S) PTE LTD',
        Address: '3155 COMMONWEALTH AVENUE WEST #05-27 CLEMENTI MALL SINGAPORE 129588',
    },
    {
        ECP: 'OPTICAL 88 (S) PTE LTD',
        Address: '2 ORCHARD TURN #B3-53 ION @ ORCHARD SINGAPORE 238801',
    },
    {
        ECP: 'OPTICAL 88 (S) PTE LTD',
        Address: '63 JURONG WEST CENTRAL 3 #B1-20/22 JURONG POINT SINGAPORE 648331',
    },
    {
        ECP: 'OPTICAL 88 (S) PTE LTD',
        Address: '930 YISHUN AVENUE 2 #01-32 NORTHPOINT CITY SINGAPORE 769098',
    },
    {
        ECP: 'OPTICAL 88 (S) PTE LTD',
        Address: '252 NORTH BRIDGE ROAD RAFFLES CITY SHOPPING CENTRE #03-19 SINGAPORE 179103',
    },
    {
        ECP: 'OPTICAL CITY PTE LTD',
        Address: 'BLK 258 PASIR RIS STREET 21 #01-15 LOYANG POINT SINGAPORE 510258',
    },
    {
        ECP: 'OPTICAL HUB LLP',
        Address: '1 WOODLANDS ROAD #01-16 JUNCTION TEN SINGAPORE 677899',
    },
    {
        ECP: 'OPTICAL ILLUSIONS',
        Address: 'BLK 263 SERANGOON CENTRAL DRIVE #01-77 SINGAPORE 550263',
    },
    {
        ECP: 'OPTICS CONNECTION PTE LTD',
        Address: '53 ANG MO KIO AVENUE 3 #01-04 AMK HUB SINGAPORE 569933',
    },
    {
        ECP: 'OPTICS CONNECTION PTE LTD',
        Address: '71 TANNERY LANE #07-05 CITY INDUSTRIAL BUILDING SINGAPORE 347807',
    },
    {
        ECP: 'OPTICS EXCELLENCE & TRADING',
        Address: 'BLK 727 CLEMENTI WEST STREET 2 #01-268 SINGAPORE 120727',
    },
    {
        ECP: 'OPTICS PREMIER PTE LTD',
        Address: '176 ORCHARD ROAD #03-13 CENTREPOINT SINGAPORE 238843',
    },
    {
        ECP: 'OPTICS TODAY',
        Address: '1 QUEENSWAY ROAD #01-51 QUEENSWAY SHOPPING CENTRE SINGAPORE 149053',
    },
    {
        ECP: 'OPTIMAL OPTIX PTE LTD',
        Address: '506 JURONG WEST STREET 52 #01-190 HONG KAH COURT SINGAPORE 640506',
    },
    {
        ECP: 'OPTIMAX VISION LLP',
        Address: 'BLK 481 TAMPINES STREET 44 #01-271 SINGAPORE 520481',
    },
    {
        ECP: 'OPTIMAX VISION LLP',
        Address: 'BLK 303 WOODLANDS STREET 31 #01-197 SINGAPORE 730303',
    },
    {
        ECP: 'OPTIQA LLP - TAMP',
        Address: 'BLK 802 TAMPINES AVENUE 4 #01-17 SINGAPORE 520802',
    },
    {
        ECP: 'OPTIQUE I.C.U.',
        Address: '30 SEMBAWANG DRIVE #02-02 SUN PLAZA SINGAPORE 757713',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '200 VICTORIA STREET #03-07 BUGIS JUNCTION SINGAPORE 188021',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '211 HOLLAND AVENUE #02-19/20 HOLLAND RD SHOPPING CENTRE SINGAPORE 278967',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '2 KALLANG AVENUE #11-06 CT HUB SINGAPORE 339407',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '9 BISHAN PLACE #02-04 JUNCTION 8 SINGAPORE 579837',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '177 RIVER VALLEY ROAD #B1-10 LIANG COURT COMPLEX SINGAPORE 179031',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '68 ORCHARD ROAD #03-22 PLAZA SINGAPURA SINGAPORE 238839',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '80 MARINE PARADE ROAD #B1-09 PARKWAY PARADE SINGAPORE 449269',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '391 ORCHARD ROAD LEVEL 4 #417-1 NGEE ANN CITY TAKASHIMAYA SC SINGAPORE 238872',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '10 TAMPINES CENTRAL 1 #04-28 TAMPINES 1 SINGAPORE 529536',
    },
    {
        ECP: 'OPTIQUE PARIS-MIKI (S) PTE LTD',
        Address: '1 HARBOURFRONT WALK #01-148 VIVOCITY SINGAPORE 098585',
    },
    {
        ECP: 'OPTIQUE TREND OPTICAL CENTRE',
        Address: '446 ANG MO KIO AVENUE 10 #01-1671 SINGAPORE 560446',
    },
    {
        ECP: 'OPTIQUE ZONE',
        Address: 'BLK 508 BISHAN STREET 11 #01-388 SINGAPORE 570508',
    },
    {
        ECP: 'OPTIQUE ZONE',
        Address: '1 QUEENSWAY ROAD #01-54 QUEENSWAY SHOPPING CENTRE SINGAPORE 149053',
    },
    {
        ECP: 'OPTIQUE ZONE',
        Address: 'BLK 123 BUKIT MERAH LANE 1 #04-102 SINGAPORE 150123',
    },
    {
        ECP: 'ORCHARD EYE CLINIC & SURGERY',
        Address: '3 MOUNT ELIZABETH #17-16 MT ELIZABETH MEDICAL CENTRE SINGAPORE 228510',
    },
    {
        ECP: 'OWL OPTICS PTE LTD',
        Address: '9 KING ALBERT PARK #01-51/52 SINGAPORE 598332',
    },
    {
        ECP: 'PACIFIC OPTICAL COMPANY',
        Address: '1 COLEMAN STREET #01-13 THE ADELPHI SINGAPORE 179803',
    },
    {
        ECP: 'PAVILION OPTICS & CONTACT LENS CENTRE PTE LTD',
        Address: '80 MARINE PARADE ROAD #B1-57 PARKWAY PARADE SINGAPORE 449269',
    },
    {
        ECP: "PEARL'S OPTICS PTE LTD",
        Address: "101 UPPER CROSS STREET #02-22/25 PEOPLE'S PARK CENTRE SINGAPORE 058357",
    },
    {
        ECP: "PEARL'S OPTICS PTE LTD",
        Address: '63 JURONG WEST CENTRAL 3 #01-10 JURONG POINT SINGAPORE 648331',
    },
    {
        ECP: "PEARL'S VISIONCARE PTE LTD",
        Address: '371 BEACH ROAD #01-52 CITY GATE SINGAPORE 199597',
    },
    {
        ECP: "PEARL'S VISIONCARE PTE LTD",
        Address: 'BLK 205 HOUGANG STREET 21 3RD LEVEL HEARTLAND MALL KOVAN SINGAPORE 530205',
    },
    {
        ECP: "PEARL'S VISIONCARE PTE LTD",
        Address: '51 TAMPINES AVENUE 4 #01-87 OUR TAMPINES HUB SINGAPORE 529684',
    },
    {
        ECP: 'PEDIAOPTIX INTERNATIONAL PTE LTD',
        Address: 'BLK 998 TOA PAYOH NORTH #01-11 MAPLETREE INDUSTRIALS SINGAPORE 318993',
    },
    {
        ECP: 'PEKING DEPARTMENTAL & OPTICAL STORE',
        Address: 'BLK 445 FAJAR ROAD #01-532 FAJAR SHOPPING CENTRE SINGAPORE 670445',
    },
    {
        ECP: 'PEKING VISION-CARE PTE LTD - BANGKIT',
        Address: 'BLK 260 BANGKIT ROAD #01-29 BT PANJANG SINGAPORE 670260',
    },
    {
        ECP: "PEOPLE'S OPTICS PTE LTD - YISHUN",
        Address: 'BLK 507 YISHUN AVENUE 4 #01-02 SINGAPORE 760507',
    },
    {
        ECP: "PEOPLE'S OPTICS PTE LTD - TAMPINES",
        Address: 'BLK 622A TAMPINES AVENUE 12 #01-05 SINGAPORE 521622',
    },
    {
        ECP: 'PERCENT EYEWEAR PTE LTD',
        Address: '111 SOMERSET ROAD #01-46/47 TRIPEONE SOMERSET SINGAPORE 238164',
    },
    {
        ECP: 'PERCEPTION PTE LTD - PAYA LEBAR',
        Address: '60 PAYA LEBAR ROAD #01-59/60 SINGAPORE 409051',
    },
    {
        ECP: 'PHILIP D IZAAC PTE LTD',
        Address: '290 ORCHARD ROAD #18-02 PARAGON SINGAPORE 238859',
    },
    {
        ECP: 'PIN PIN OPTICAL COMPANY PTE LTD',
        Address: '51 UBI AVENUE 1 #05-25 PAYA UBI INDUSTRIAL PARK SINGAPORE 408933',
    },
    {
        ECP: 'PLUS MINUS EYEPOINT',
        Address: 'BLK 46 HOLLAND DRIVE #01-369 SINGAPORE 270046',
    },
    {
        ECP: 'PRECIOUS EYES OPTICAL ELITE',
        Address: 'BLK 445 PASIR RIS DRIVE 6 #01-98 SINGAPORE 510445',
    },
    {
        ECP: 'PRECISION OPTICS',
        Address: '101 THOMSON ROAD #01-44 UNITED SQUARE SHOPPING MALL SINGAPORE 307591',
    },
    {
        ECP: 'PRELECTOR EYECARE CENTRE',
        Address: 'BLK 351 JURONG EAST STREET 31 #01-81 SINGAPORE 600351',
    },
    {
        ECP: 'PREVIEW VIDEO & OPTICS PTE LTD',
        Address: 'BLK 301 SERANGOON AVENUE 2 #01-326 SINGAPORE 550301',
    },
    {
        ECP: 'PRO EYEZ OPTOMETRISTS',
        Address: 'BLK 118 RIVERVALE DRIVE #02-24 RIVERVALE PLAZA SINGAPORE 540118',
    },
    {
        ECP: 'QQ OPTOMETRIST PTE LTD',
        Address: 'BLK 762 JURONG WEST STREET 75 #01-286 SINGAPORE 640762',
    },
    {
        ECP: 'RADIANT OPTIC & CONTACT LENS CENTRE',
        Address: 'BLK 163 BT MERAH CENTRAL #02-3599D SINGAPORE 150163',
    },
    {
        ECP: 'RAFFLES EYE CENTRE',
        Address: '585 NORTH BRIDGE ROAD #16-00 SINGAPORE 188770',
    },
    {
        ECP: 'RAFFLES HOSPITAL PTE LTD',
        Address: '585 NORTH BRIDGE ROAD #16-00 SINGAPORE 188770',
    },
    {
        ECP: 'RAFFLES JAPANESE CLINIC PTE LTD',
        Address: '1 SCOTTS ROAD #05-06/11 SHAW CENTRE SINGAPORE 228208',
    },
    {
        ECP: 'RAYLITE OPTICAL & EYE CARE PTE LTD',
        Address: 'BLK 201D TAMPINES STREET 21 #01-1133 SINGAPORE 524201',
    },
    {
        ECP: 'RED DOT VISIONCARE',
        Address: 'BLK 146 POTONG PASIR AVENUE 1 #01-131 SINGAPORE 350146',
    },
    {
        ECP: 'RESIDENTS EYECARE PTE LTD',
        Address: 'BLK 643 SENJA CLOSE #01-04 SINGAPORE 670643',
    },
    {
        ECP: 'RESIDENTS OPTICAL PTE LTD',
        Address: 'BLK 510 BEDOK NORTH STREET 3 #01-41 SINGAPORE 460510',
    },
    {
        ECP: 'RESIDENTS OPTICAL PTE LTD',
        Address: 'BLK 965 JURONG WEST STREET 93 #01-209 SINGAPORE 640965',
    },
    {
        ECP: 'RICHARD WOON OPTOMETRIST & CONTACT LENS CENTRE',
        Address: 'BLK 465 CRAWFORD LANE #01-32 CRAWFORD CENTRE SINGAPORE 190465',
    },
    {
        ECP: 'SENG LI OPTOMETRISTS',
        Address: '1 QUEENSWAY ROAD #01-53 QUEENSWAY SHOPPING CENTRE SINGAPORE 149053',
    },
    {
        ECP: 'SHINAGAWA OPTICAL PTE LTD',
        Address: '501 ORCHARD ROAD #03-03 WHEELOCK PLACE SINGAPORE 238880',
    },
    {
        ECP: 'SHIRLEY OPTICAL',
        Address: '50 EAST COAST ROAD #02-34B ROXY SQUARE SINGAPORE 428769',
    },
    {
        ECP: 'SIGHTONOMY PTE LTD',
        Address: '60B SMITH STREET SINGAPORE 058963',
    },
    {
        ECP: 'SIGLAP OPTICAL',
        Address: '55 SIGLAP ROAD #01-03/04 SIGLAP CENTRE SINGAPORE 455871',
    },
    {
        ECP: 'SIN CHEW OPTICS & CONTACT LENS CENTRE',
        Address: '301 UPPER THOMSON ROAD #01-05 THOMSON PLAZA SINGAPORE 574408',
    },
    {
        ECP: 'SINCERE OPTICS PTE LTD',
        Address: '144 UPPER BUKIT TIMAH ROAD #03-20 BEAUTY WORLD CENTRE SINGAPORE 588177',
    },
    {
        ECP: 'SINGAPORE NATIONAL EYE CENTRE PTE LTD',
        Address: '11 3RD HOSPITAL AVENUE SINGAPORE 168751',
    },
    {
        ECP: 'SINGAPORE-POLYTECHNIC',
        Address: '500 DOVER ROAD W115 OPTOMETRY CENTRE CPT CENTRE SINGAPORE 139651',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '3 TEMASEK BOULEVARD #01-367 SUNTEC CITY MALL SINGAPORE 038983',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '391 ORCHARD ROAD #B2-27 NGEE ANN CITY TAKASHIMAYA SC SINGAPORE 238872',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '4 TAMPINES CENTRAL 5 #B1-21 TAMPINES MALL SINGAPORE 529510',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '302 TIONG BAHRU ROAD #01-144 TIONG BAHRU PLAZA SINGAPORE 168732',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '1 HARBOURFRONT WALK #B1-14 VIVOCITY SINGAPORE 098585',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '1 BUKIT BATOK CENTRAL LINK #02-06 WEST MALL SINGAPORE 658713',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '3 GATEWAY DRIVE #B1-33/34 WESTGATE SINGAPORE 608532',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '1 PASIR RIS DRIVE 3 #01-11A/11B WHITE SANDS SINGAPORE 518457',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '83 PUNGGOL CENTRAL #01-07 WATERWAY POINT SINGAPORE 828761',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '10 SINARAN DRIVE #01-138 SQUARE 2 SINGAPORE 307506',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '33 SENGKANG WEST AVENUE #01-42 SELETAR MALL SINGAPORE 797653',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '313 ORCHARD ROAD #B1-17/18 313@SOMERSET SINGAPORE 238895',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '53 ANG MO KIO AVENUE 3 #01-23 AMK HUB SINGAPORE 569933',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '311 NEW UPPER CHANGI ROAD #B1-36 BEDOK MALL SINGAPORE 467360',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '5 CHANGI BUSINESS PARK CENTRAL 1 #01-49 CHANGI CITY POINT SINGAPORE 486038',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '180 KITCHENER ROAD #B1-08 CITY SQUARE MALL SINGAPORE 208539',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '3155 COMMONWEALTH AVENUE WEST #01-10 CLEMENTI MALL SINGAPORE 129588',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '1 SENGKANG SQUARE #02-08 COMPASS ONE SINGAPORE 545078',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '1 KIM SENG PROMENADE #01-39/40 GREAT WORLD CITY SINGAPORE 237994',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '17 PETIR ROAD #B1-33 HILLION MALL SINGAPORE 678278',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '2 JURONG EAST STREET 21 #01-71 IMM BUILDING SINGAPORE 609601',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '78 AIRPORT BOULEVARD #01-211/212/213 JEWEL CHANGI AIRPORT SINGAPORE 819666',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '1 JURONG WEST CENTRAL 2 #02-22/23 JURONG POINT SINGAPORE 648331',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '2 BAYFRONT AVENUE #B2-47/48 THE SHOPPES AT MARINA BAY SANDS SINGAPORE 018972',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '23 SERANGOON CENTRAL #B2-23/24 NEX SINGAPORE 556083',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '930 YISHUN AVENUE 2 #B1-32 NORTHPOINT SHOPPING CENTRE SINGAPORE 769098',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '80 MARINE PARADE ROAD #B1-76A-77 PARKWAY PARADE SINGAPORE 449269',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '68 ORCHARD ROAD #02-29/29A PLAZA SINGAPURA SINGAPORE 238839',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '252 NORTH BRIDGE ROAD #B1-32 RAFFLES CITY SHOPPING CENTRE SINGAPORE 179103',
    },
    {
        ECP: 'SOONG CHOON OPTICS PTE LTD',
        Address: '5 TAMPINES CENTRAL 6 #01-20/21 TELEPARK SINGAPORE 529482',
    },
    {
        ECP: 'SOONG CHOON OPTICS PTE LTD',
        Address: 'BLK 183 TOA PAYOH CENTRAL #01-278 SINGAPORE 310183',
    },
    {
        ECP: 'SOONG CHOON OPTICS PTE LTD',
        Address: 'BLK 203 HOUGANG STREET 21 #01-51 SINGAPORE 530203',
    },
    {
        ECP: 'SOONG CHOON OPTICS PTE LTD',
        Address: '60 PAYA LEBAR ROAD #B1-48 PAYA LEBAR SQUARE SINGAPORE 409051',
    },
    {
        ECP: 'SOONG CHOON OPTICS PTE LTD',
        Address: "101 UPPER CROSS STREET #02-71/72/73 PEOPLE'S PARK CENTRE SINGAPORE 058357",
    },
    {
        ECP: 'SOONG CHOON OPTICS PTE LTD',
        Address: '1028 UPPER SERANGOON SINGAPORE 534766',
    },
    {
        ECP: 'SOONG CHOON OPTICS PTE LTD',
        Address: 'BLK 513 TAMPINES CENTRAL 1 #01-158 SINGAPORE 520513',
    },
    {
        ECP: 'SPECS SMITH PTE LTD',
        Address: '146 POTONG PASIR AVENUE 1 #01-103 SINGAPORE 350146',
    },
    {
        ECP: 'SPECTACLE CULTURE PTE LTD',
        Address: 'BLK 531 UPPER CROSS STREET #02-66 HONG LIM COMPLEX SINGAPORE 050531',
    },
    {
        ECP: 'SPECTACLES CENTRE',
        Address: 'BLK 201D TAMPINES STREET 21 #01-1113 SINGAPORE 524201',
    },
    {
        ECP: 'SPECTACULAR-ICARE',
        Address: '9 YIO CHU KANG ROAD #01-08 SPACE @ KOVAN SINGAPORE 545523',
    },
    {
        ECP: 'SPEX@BISHAN PTE LTD',
        Address: 'BLK 510 BISHAN STREET 13 #01-05 SINGAPORE 570510',
    },
    {
        ECP: 'STAN ISAACS PTE LTD',
        Address: '1 ORCHARD BOULEVARD #13-08 CAMDEN MEDICAL CENTRE SINGAPORE 248649',
    },
    {
        ECP: 'STAR OPTICAL COMPANY',
        Address: '402 ORCHARD ROAD #02-03 DELFI ORCHARD SINGAPORE 238876',
    },
    // {
    // ECP: 'STRAITS CONTACT LENS & OPTICAL CENTRE',
    // Address: 'BLK 513 TAMPINES CENTRAL 1 #01-166 SINGAPORE 520513',
    // },
    // {
    // ECP: 'STRAITS CONTACT LENS & OPTICAL CENTRE',
    // Address: 'BLK 443 CLEMENTI AVENUE 3 #01-73 SINGAPORE 120443',
    // },
    // {
    // ECP: 'STRAITS CONTACT LENS & OPTICAL CENTRE',
    // Address: 'BLK 262 SERANGOON CENTRAL DRIVE #01-95 SINGAPORE 550262',
    // },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '200 VICTORIA STREET #01-43/44 BUGIS JUNCTION SINGAPORE 188021',
    },
    {
        ECP: 'SUNGLASS HUT (SOUTH EAST ASIA) PTE LTD',
        Address: '1 TEMASEK AVENUE #13-01 MILLENIA TOWER SINGAPORE 039192',
    },
    {
        ECP: 'SUNGLASS HUT (SOUTH EAST ASIA) PTE LTD',
        Address: '10 AIRPORT BOULEVARD #02-60-61 TERMINAL 4 SINGAPORE 819664',
    },
    {
        ECP: 'SUNGLASS HUT (SOUTH EAST ASIA) PTE LTD',
        Address: '23 SERANGOON CENTRAL #B1-26 NEX SINGAPORE 556083',
    },
    {
        ECP: 'SUNGLASS HUT (SOUTH EAST ASIA) PTE LTD',
        Address: '68 ORCHARD ROAD #01-25 PLAZA SINGAPURA SINGAPORE 238839',
    },
    {
        ECP: 'SUNGLASS HUT (SOUTH EAST ASIA) PTE LTD',
        Address: '80 AIRPORT BOULEVARD TERMINAL 1 SINGAPORE 819642',
    },
    {
        ECP: 'SUNGLASS HUT (SOUTH EAST ASIA) PTE LTD',
        Address: '60 AIRPORT ROAD #026-114 TERMINAL 2 SINGAPORE 819643',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: '112 EAST COAST ROAD #01-06/07 I12 KATONG SINGAPORE 428802',
    },
    {
        ECP: 'SUNNY EYEWEAR',
        Address: '14 SCOTTS ROAD #02-100 FAR EAST PLAZA SINGAPORE 228213',
    },
    {
        ECP: 'SUNNY OPTICS AND CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 376 BUKIT BATOK STREET 31 #01-100 SINGAPORE 650376',
    },
    {
        ECP: 'SYSTEMS OPTICS',
        Address: '111 NORTH BRIDGE ROAD #01-24 PENINSULA PLAZA SINGAPORE 179098',
    },
    {
        ECP: 'SPECTACLE HUT PTE LTD',
        Address: "21 CHOA CHU KANG AVENUE 4 #B1-18 LOT ONE SHOPPERS' MALL SINGAPORE 689812",
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: '133 CANBERRA VIEW #01-13 CANBERRA PLAZA SINGAPORE 750133',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 41 JALAN TIGA #01-06 SINGAPORE 390041',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 638 JURONG WEST STREET 61 #01-07 SINGAPORE 640638',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 4 WOODLANDS STREET 12 #02-18 SINGAPORE 738623',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 628 SENJA ROAD #01-06 BUKIT PANJANG SINGAPORE 670628',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 825 TAMPINES STREET 81 #01-72 SINGAPORE 520825',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: '11 TAMPINES STREET 32 #01-12 TAMPINES MART SINGAPORE 529287',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 883 WOODLANDS STREET 82 #01-490 SINGAPORE 730883',
    },
    {
        ECP: 'TAMPINES OPTICAL & CONTACT LENS CENTRE PTE LTD',
        Address: 'BLK 888 WOODLANDS DRIVE 50 #01-743 SINGAPORE 730888',
    },
    {
        ECP: 'TAN TOCK SENG HOSPITAL PTE LTD',
        Address: '11 JALAN TAN TOCK SENG TAN TOCK SENG HOSPITAL SINGAPORE 308433',
    },
    {
        ECP: 'TECK SEONG OPTICAL CO',
        Address: 'BLK 2 EVERTON PARK #01-49 SINGAPORE 081002',
    },
    {
        ECP: 'TECK YEW OPTICAL AND WATCHES COMPANY',
        Address: 'BLK 204 HOUGANG STREET 21 #01-127 SINGAPORE 530204',
    },
    {
        ECP: 'THE EYE OPTIQUE',
        Address: 'BLK 539 BEDOK NORTH STREET 3 #01-623 SINGAPORE 460539',
    },
    {
        ECP: 'THE EYE PLACE PTE LTD',
        Address: '223 SOUTH BRIDGE ROAD SINGAPORE 058772',
    },
    {
        ECP: 'THE EYECARE INITIATIVE - OPTICAL PTE LTD',
        Address: 'BLK 827 TAMPINES STREET 81 #01-146 SINGAPORE 520827',
    },
    {
        ECP: 'THE EYECLINIC BY DR CHERYL LEE',
        Address: '333A ORCHARD ROAD #04-24/25 MANDARIN GALLERY SINGAPORE 238897',
    },
    {
        ECP: 'THE EYES INC PTE LTD',
        Address: 'BLK 721 ANG MO KIO AVENUE 8 #01-2803 SINGAPORE 560721',
    },
    {
        ECP: 'THE LENS MEN PTE LTD',
        Address: '83 MARINE PARADE CENTRAL #01-558 MARINE PARADE PROMENADE SINGAPORE 440083',
    },
    {
        ECP: 'THE OPTIQUE LAB PTE LTD',
        Address: '11 RIVERVALE CRESCENT #02-04 RIVERVALE MALL SINGAPORE 545082',
    },
    {
        ECP: 'THE OPTOMETRISTS @ OPTIC POINT PTE LTD',
        Address: '360 ORCHARD ROAD #09-02 INTERNATIONAL BUILDING SINGAPORE 238869',
    },
    {
        ECP: 'THE POINT OPTICAL PTE LTD',
        Address: 'BLK 703 ANG MO KIO AVENUE 8 #01-2533 SINGAPORE 560703',
    },
    {
        ECP: 'THE POINT OPTICAL PTE LTD',
        Address: 'BLK 624 CHOA CHU KANG STREET 62 #01-222A SINGAPORE 680624',
    },
    {
        ECP: 'THE POINT OPTICAL PTE LTD',
        Address: 'BLK 183 TOA PAYOH CENTRAL #01-310 SINGAPORE 310183',
    },
    {
        ECP: 'THE SPECTALK@18 PRIVATE LIMITED',
        Address: '18 TAI SENG STREET #01-27 SINGAPORE 539775',
    },
    {
        ECP: 'THE STRAITS EYE CENTRE PTE LTD',
        Address: '1 ORCHARD BOULEVARD #06-07/08 CAMDEN MEDICAL CENTRE SINGAPORE 248649',
    },
    {
        ECP: 'TIEN YA OPTOMETRISTS LLP',
        Address: 'BLK 252 JURONG EAST STREET 24 #01-159 SINGAPORE 600252',
    },
    {
        ECP: 'TIONG BAHRU EYECARE PTE LTD',
        Address: 'BLK 11A BOON TIONG ROAD #01-10 SINGAPORE 161011',
    },
    {
        ECP: 'TITUS EYE CARE PTE LTD',
        Address: '10 JALAN SERENE #02-15 SERENE CENTRE SINGAPORE 258748',
    },
    {
        ECP: 'TREND OPTICS',
        Address: '10 SINARAN DRIVE #04-32 SQUARE 2 SINGAPORE 307506',
    },
    {
        ECP: 'TRENDY EYES PTE LTD - CHANGI',
        Address: '799 NEW UPPER CHANGI ROAD #02-15 BEDOK POINT SINGAPORE 467351',
    },
    {
        ECP: 'TRENDY EYES PTE LTD - BEDOK',
        Address: 'BEDOK NORTH STREET 1 #02-09 DJITSUN MALL BEDOK SINGAPORE 469661',
    },
    {
        ECP: 'UNIQUE OPTIC',
        Address: 'BLK 27A CHAI CHEE ROAD #01-377 SINGAPORE 461027',
    },
    {
        ECP: 'UNITED EYECARE (BOON KENG) LLP',
        Address: '27 BENDEMEER ROAD #01-641 SINGAPORE 330027',
    },
    {
        ECP: 'UNITED EYECARE (NOVENA) LLP',
        Address: '10 SINARAN DRIVE #03-108 SINGAPORE 307506',
    },
    {
        ECP: 'UNIVERSAL OPTICAL PTE LTD',
        Address: '2 TAMPINES CENTRAL 5 #01-30 CENTURY SQUARE SINGAPORE 529509',
    },
    {
        ECP: 'UNIVERSAL OPTICAL PTE LTD',
        Address: '14 SCOTTS ROAD #04-123 FAR EAST PLAZA SINGAPORE 228213',
    },
    {
        ECP: 'UNIVERSAL OPTICAL PTE LTD',
        Address: '23 SERANGOON CENTRAL #02-41 NEX SINGAPORE 556083',
    },
    {
        ECP: 'UNIVERSAL OPTICAL PTE LTD',
        Address: '10 SINARAN DRIVE #01-35/42 SQUARE 2 SINGAPORE 307506',
    },
    {
        ECP: 'UNIVERSAL OPTICAL PTE LTD',
        Address: 'BLK 9012 TAMPINES STREET 93 #02-153 SINGAPORE 528845',
    },
    {
        ECP: 'VISION & HEARING CARE CENTRE',
        Address: 'BLK 134 JURONG GATEWAY ROAD #02-309D SINGAPORE 600134',
    },
    {
        ECP: 'VISION 27',
        Address: '201E TAMPINES STREET 23 #01-98 SINGAPORE 527201',
    },
    {
        ECP: 'VISION AND PERCEPTION PTE LTD',
        Address: '220 ORCHARD ROAD #03-05 MIDPOINT ORCHARD SINGAPORE 238852',
    },
    {
        ECP: 'VISION CARE OPTICS - BUANGKOK',
        Address: 'BLK 275D COMPASSVALE LINK #01-07 UNIT 07 BUANGKOK MRT SINGAPORE 544275',
    },
    {
        ECP: 'VISION DIRECT SOUTH EAST ASIA PTE LTD',
        Address: '40 JALAN PEMIMPIN #03-12 A5 TAT ANN BUILDING SINGAPORE 577185',
    },
    {
        ECP: 'VISION IMAGE OPTICAL CENTRE',
        Address: 'BLK 709 ANG MO KIO AVENUE 8 #01-2591 SINGAPORE 560709',
    },
    {
        ECP: 'VISION LAB EYEWEAR PREMIUM PTE LTD',
        Address: '24 SIN MING LANE #02-96 MIDVIEW CITY SINGAPORE 573970',
    },
    {
        ECP: 'VISION LAB EYEWEAR PREMIUM PTE LTD',
        Address: '13 STAMFORD ROAD #B2-33 CAPITOL PIAZZA SINGAPORE 178905',
    },
    {
        ECP: 'VISION LAB EYEWEAR PREMIUM PTE LTD',
        Address: '1 JURONG EAST STREET 21 #02-17/18 NTF GENERAL HOSPITAL SINGAPORE 609606',
    },
    {
        ECP: 'VISION LAB EYEWEAR PREMIUM PTE LTD',
        Address: '7 WALLICH STREET #B1-24 TANJONG PAGAR CENTRE SINGAPORE 078884',
    },
    {
        ECP: 'VISION LAB EYEWEAR PREMIUM PTE LTD',
        Address: '83 PUNGGOL CENTRAL #02-36 WATERWAY POINT SINGAPORE 828761',
    },
    {
        ECP: 'VISION PRO OPTIC',
        Address: 'BLK 151 BUKIT BATOK STREET 11 #01-254 SINGAPORE 650151',
    },
    {
        ECP: 'VISION STYLE OPTICAL AND CONTACT LENS CENTRE',
        Address: 'BLK 513 TAMPINES CENTRAL 1 #01-156 SINGAPORE 520513',
    },
    {
        ECP: 'VISIONLINK EYECARE',
        Address: '18 YISHUN AVENUE 9 #02-53 JUNCTION NINE SINGAPORE 768897',
    },
    {
        ECP: 'VISIONLINK OPTICS & CONTACT LENS',
        Address: 'BLK 624 CHOA CHU KANG STREET 62 #01-228 SINGAPORE 680624',
    },
    {
        ECP: 'VISIOR OPTICAL LLP',
        Address: 'BLK 463 JURONG WEST ST 41 #01-558 SINGAPORE 640463',
    },
    {
        ECP: "VISUAL ART'S OPTICS & CONTACT LENS CENTRE",
        Address: '18 YISHUN AVENUE 9 #01-67 JUNCTION NINE SINGAPORE 768897',
    },
    {
        ECP: "VISUAL ART'S OPTICS & CONTACT LENS CENTRE",
        Address: '60 PAYA LEBAR ROAD #01-63 PAYA LEBAR SQUARE SINGAPORE 409051',
    },
    {
        ECP: "VISUAL ART'S OPTICS & CONTACT LENS CENTRE",
        Address: '101 YISHUN AVENUE 5 #01-77 SINGAPORE 760101',
    },
    {
        ECP: "VISUAL ART'S OPTICS & CONTACT LENS CENTRE",
        Address: 'BLK 809 HOUGANG CENTRAL #01-186 SINGAPORE 530809',
    },
    {
        ECP: "VISUAL ART'S OPTICS & CONTACT LENS CENTRE",
        Address: '30 SEMBAWANG DRIVE #01-20 SUN PLAZA SINGAPORE 757713',
    },
    {
        ECP: 'VIZION OPTICS PTE LTD',
        Address: '720 ANG MO KIO AVE 6 #01-4112 SINGAPORE 560720',
    },
    {
        ECP: 'VZION OPTIQUE',
        Address: '53 ANG MO KIO AVENUE 3 #02-16 AMK HUB SINGAPORE 569933',
    },
    {
        ECP: 'W EYECARE PTE LTD',
        Address: '211 HENDERSON ROAD #13-03 HENDERSON INDUSTRIAL PARK SINGAPORE 159552',
    },
    {
        ECP: 'W HUB PRIVATE LIMITED',
        Address: 'BLK 205 BEDOK NORTH STREET 1 #01-377 SINGAPORE 460205',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '311 NEW UPPER CHANGI ROAD #01-81/82 BEDOK MALL SINGAPORE 467360',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '176 ORCHARD ROAD #01-34/35/36/37/38/39 SINGAPORE 238843',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '1 SENGKANG SQUARE #02-48 COMPASS ONE SINGAPORE 545078',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '583 ORCHARD ROAD #01-26/27/35/37 FORUM SHOPPING MALL SINGAPORE 238884',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '1 KIM SENG PROMENADE #02-122 GREAT WORLD CITY SINGAPORE 237994',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '1 HOUGANG STREET 91 #01-33 HOUGANG 1 SINGAPORE 538692',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '219 HENDERSON ROAD #11-01/02 SINGAPORE 159556',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '50 JURONG GATEWAY ROAD #02-23 JEM SINGAPORE 608549',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '1 STADIUM PLACE #01-48 KALLANG WAVE MALL SINGAPORE 397628',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '6 RAFFLES BOULEVARD #02-20/21/22 MARINA SQUARE SINGAPORE 039594',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '3 TEMASEK BOULEVARD #01-400 SUNTEC TOWER 5 SINGAPORE 038983',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '163 TANGLIN ROAD #03-124 TANGLIN MALL SINGAPORE 247933',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '1 HARBOURFRONT WALK #01-143/144 VIVOCITY SINGAPORE 098585',
    },
    {
        ECP: 'W OPTICS PTE LTD',
        Address: '501 ORCHARD ROAD #03-03 WHEELOCK PLACE SINGAPORE 238880',
    },
    {
        ECP: 'WEST ISLAND OPTICAL CENTRE',
        Address: 'BLK 598 YISHUN RING ROAD #B1-18 WISTERIA MALL SINGAPORE 768698',
    },
    {
        ECP: 'WEST POINT OPTOMETRIST LLP',
        Address: 'BLK 735 PASIR RIS STREET 72 #01-360 PASIR RIS WEST PLAZA SINGAPORE 510735',
    },
    {
        ECP: 'WESTERN VISION PTE LTD',
        Address: '1 QUEENSWAY ROAD #01-01 QUEENSWAY SHOPPING CENTRE SINGAPORE 149053',
    },
    {
        ECP: 'WISDOM OPTICAL',
        Address: 'BLK 1 JALAN BUKIT MERAH #01-4528 SINGAPORE 150001',
    },
    {
        ECP: 'WISELAND CONTACT LENS & OPTICAL CENTRE',
        Address: 'BLK 11A BOON TIONG ROAD #02-11 SINGAPORE 161011',
    },
    {
        ECP: 'WISELAND CONTACT LENS & OPTICAL CENTRE',
        Address: '134 JURONG GATEWAY ROAD #01-305 SINGAPORE 600134',
    },
    {
        ECP: 'WONDERLENS OPTICAL',
        Address: 'BLK 925 YISHUN CENTRAL 1 #01-221 SINGAPORE 760925',
    },
    {
        ECP: 'XIMEI OPTICS',
        Address: 'BLK 252 JURONG EAST STREET 24 #01-181 SINGAPORE 600252',
    },
    {
        ECP: 'YAK PTE LTD',
        Address: '22A SMITH STREET SINGAPORE 058936',
    },
    {
        ECP: 'YAK VISIONCARE',
        Address: '383 BUKIT TIMAH ROAD #01-01A ALOCASSIA APARTMENT SINGAPORE 259727',
    },
    {
        ECP: 'YEG OPTICAL LENS SERVICES PTE LTD',
        Address: '18 JALAN MEMBINA #02-01 TIONG BAHRU COURT SINGAPORE 164018',
    },
    {
        ECP: 'YI EYECARE',
        Address: 'BLK 624 CHOA CHU KANG STREET 62 #01-236 SINGAPORE 680624',
    },
    {
        ECP: 'YI SPECS PTE LTD',
        Address: 'BLK 168 PUNGGOL FIELD #01-06 PUNGGOL PLAZA CORAL EDGE LRT SINGAPORE 820168',
    },
    {
        ECP: 'YOUR EYE GUARDIAN',
        Address: 'BLK 18 JALAN MEMBINA #02-10 BESIDE TIONG BAHRU MRT SINGAPORE 164018',
    },
    {
        ECP: 'YOURS 10-01 PTE LTD',
        Address: '3155 COMMONWEALTH AVENUE WEST #04-43 THE CLEMENTI MALL SINGAPORE 129588',
    },
    {
        ECP: 'YU YI OPTIC LLP',
        Address: 'BLK 138 TAMPINES STREET 11 #01-98 SINGAPORE 521138',
    },
    {
        ECP: 'YU YI OPTIC LLP',
        Address: 'BLK 481 TAMPINES STREET 44 #01-273 SINGAPORE 520481',
    },
    {
        ECP: 'YU YI OPTIC LLP',
        Address: 'BLK 123 TOA PAYOH LORONG 1 #01-507 SINGAPORE 310123',
    },
    {
        ECP: 'ZEN MEDICAL PTE LTD',
        Address: '6 NAPIER ROAD #07-10 GLENEAGLES MEDICAL CENTRE SINGAPORE 258499',
    },
];

export default OutletList;
