import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Utils from 'lib/Utils';
import HomePageBanner from './HomePageBanner';
import HomePageBody from './HomePageBody';

const Home = (): JSX.Element => {
    const location = useLocation();
    const { hash } = location;

    const {
        GTM: {
            triggerPageView,
        },
    } = Utils;

    triggerPageView();

    useEffect(() => {
        if (hash) {
            const hashContainer = document.querySelector(hash);
            hashContainer?.scrollIntoView({
                behavior: 'instant',
            });
        }
    }, [location]);

    return (
        <>
            <HomePageBanner />

            <HomePageBody />
        </>
    );
};

export default Home;
